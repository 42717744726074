import { connect } from 'react-redux';
import * as selectors from '../../app/selectors';
import * as Selectors from './selectors';
import Sidebar from '../../components/sidebar';

export default connect(
    (state: any) => ({
        summary: selectors.getSummary(state),
        course: selectors.getCourse(state),
        lesson: selectors.getChapter(state), // TODO: change Chapter to Lesson
        questions: selectors.getQuestions(state),
        toggle: Selectors.getToggle(state),
        classroomKey: selectors.getClassroomKey(state)
    })
)(Sidebar);
import * as FactType from './types';
import * as FactSelector from './selectors';
import * as selectors from './../selectors';

export const fetchFact = (courseId: string, chapter: string) => async (dispatch: any, getState: any, { api, errorHandler, Type }: any) => {
    const fact = await api.getFact(courseId, chapter);
    await dispatch({ type: FactType.FACT_LOADED, payload: fact.data });

    if(fact.data){
        const userAnswers = selectors.getUserAnswers(getState());
        const userChapter = userAnswers.filter((item:any) => item.get('chapter') == chapter);
        dispatch({ type: FactType.FACT_OPENED, payload: userChapter.size ? false : true });
    }
};

export const updateTry = () => async (dispatch: any, getState: any) => {
    if(FactSelector.isLoaded(getState()) && FactSelector.getFactContent(getState())){
        const tries = FactSelector.tries(getState()) + 1;
        dispatch({ type: FactType.UPDATE_TRY, payload: tries });
    }
};

export const resetTry = () => async (dispatch: any) => dispatch({ type: FactType.UPDATE_TRY, payload: 0 });
export const setIsOpen = (isOpen:boolean) => async (dispatch: any) =>  dispatch({ type: FactType.FACT_OPENED, payload: isOpen });

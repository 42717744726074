import * as React from 'react';
import { Tag } from "@blueprintjs/core";
import './console.css';

interface ConsoleProps {
    logs: string[];
}

export default class extends React.Component<ConsoleProps, {}> {
    renderLog(log: any, i: number) {
        return <pre className="output" key={i}>{log.message}</pre>;
    }

    render() {
        const { logs } = this.props;

        return (
            <div className="console-wrap">
                <Tag className="output-label">Console</Tag>
                <div className="console-body">
                    {logs.map((log: any, i: number) => this.renderLog(log, i))}
                </div>
            </div>
        )
    }
}

import Loadable from 'react-loadable';
import Loading from '../components/loading';

export const Chapter = Loadable({
    loader: () => import('./chapter'),
    loading: () => Loading
});

export const Sidebar = Loadable({
    loader: () => import('./sidebar'),
    loading: () => Loading
});

export const Fact = Loadable({
    loader: () => import('./fact'),
    loading: () => Loading
});

export const Console = Loadable({
    loader: () => import('./console'),
    loading: () => Loading
});

export const Tips = Loadable({
    loader: () => import('./tips'),
    loading: () => Loading
});

export const QuestionNavigation = Loadable({
    loader: () => import('./question-navigation'),
    loading: () => Loading
});

export const MultipleSelectionQuestion = Loadable({
    loader: () => import('./multiple-selection-question'),
    loading: () => Loading
});

export const Screen = Loadable({
    loader: () => import('./screen'),
    loading: () => Loading
});

export const ShowAnswerBtn = Loadable({
    loader: () => import('./show-answer-btn'),
    loading: () => Loading
});

export const SkipButton = Loadable({
    loader: () => import('./skip-button'),
    loading: () => Loading
});

export const BrowserDisplay = Loadable({
    loader: () => import('./browser-display'),
    loading: () => Loading
});

export const SQLTable = Loadable({
    loader: () => import('./sql-table'),
    loading: () => Loading
});

export const SQLConsole = Loadable({
    loader: () => import('./sql-console'),
    loading: () => Loading
});
let config: any = {};

const env = process.env.REACT_APP_READER_MODE || process.env.NODE_ENV

switch (env) {
    case 'production':
        config = {
            gateway: 'https://gateway.codestop.io',
            "platformLoginURL": 'https://codestop.io/login',
            "platformURL": 'https://codestop.io',
            "readonlyToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJDb2RlU3RvcCBSZWFkb25seSBSZWFkZXIgQVBJIiwiaWF0IjoxNTk4NzUxOTkxfQ.RJ--ZeyDCXw6Q5fe2zdojo3DIhYByu4t1gYCLgkEmSw"
        };
        /*
        config = {
            gateway: 'http://gateway.cebu.codingavenue.com'
        };
        */
        break;
    case 'test':
        config = {
            gateway: undefined,
            "platformLoginURL": 'http://localhost:8000/login',
            "platformURL": 'http://localhost:8000',
            "readonlyToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJDb2RlU3RvcCBSZWFkb25seSBSZWFkZXIgQVBJIiwiaWF0IjoxNTk4NzUxOTkxfQ.RJ--ZeyDCXw6Q5fe2zdojo3DIhYByu4t1gYCLgkEmSw"
        };
        break;
    case 'staging':
        config = {
            "gateway": 'https://gateway.codestop.dev.hireplicity.com',
            "platformLoginURL": 'https://codestop.dev.hireplicity.com/login',
            "platformURL": 'https://codestop.dev.hireplicity.com',
            "readonlyToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJDb2RlU3RvcCBSZWFkb25seSBSZWFkZXIgQVBJIiwiaWF0IjoxNTk4NzUxOTkxfQ.RJ--ZeyDCXw6Q5fe2zdojo3DIhYByu4t1gYCLgkEmSw"
        };
        break;
        /**
         * FYI content-staging wasn't setup in the new staging server.
         */
    case 'content-staging':
        config = {
            "gateway": 'https://gateway.cs-content.dev.hireplicity.com',
            "platformLoginURL": 'https://cs-content.dev.hireplicity.com/login',
            "platformURL": 'https://cs-content.dev.hireplicity.com',
            "readonlyToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJDb2RlU3RvcCBSZWFkb25seSBSZWFkZXIgQVBJIiwiaWF0IjoxNTk4NzUxOTkxfQ.RJ--ZeyDCXw6Q5fe2zdojo3DIhYByu4t1gYCLgkEmSw"
        };
        break;
    default:
        config = {
            "gateway": 'http://localhost:8080',
            "platformLoginURL": 'http://localhost:8000/login',
            "platformURL": 'http://localhost:8000',
            "readonlyToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJDb2RlU3RvcCBSZWFkb25seSBSZWFkZXIgQVBJIiwiaWF0IjoxNTk4NzUxOTkxfQ.RJ--ZeyDCXw6Q5fe2zdojo3DIhYByu4t1gYCLgkEmSw"
        };
}

export default config;

import * as React from 'react';
import Loadable from 'react-loadable';
import Loading from '../components/loading';

const Text = Loadable({
    loader: () => import('../components/text'),
    loading: () => Loading,
});

const SingleSelection = Loadable({
    loader: () => import('../app/single-selection-question/container'),
    loading: () => Loading
})

const MultipleSelection = Loadable({
    loader: () => import('../app/multiple-selection-question/container'),
    loading: () => Loading
})

const CodeReview = Loadable({
    loader: () => import('../app/code-review/container'),
    loading: () => Loading
})

const REPL = Loadable({
    loader: () => import('../app/repl/container'),
    loading: () => Loading
})

export default ({ question }: any): any => {
    switch (question.get('type')) {
        case 'text':
        case 'SECTION':
            return <Text node={question} />;
        case 'REPL':
            return <REPL node={question} />;
        case 'MS':
            return <MultipleSelection node={question} />;
        case 'SS':
            return <SingleSelection node={question} />;
        case 'CR':
            return <CodeReview node={question} />;
    }
}
import config from './config';
import { keyBy, map } from 'lodash';
import { Map, OrderedMap, fromJS } from 'immutable';
import CodeStopApi from './lib/codestop/codestop-api';
import { 
    UserRoleFetchError,
    UserNotFound,
    UserAnswerFetchError,
    CourseError,
    CourseNotFound,
    CourseSummaryFetchError,
    CourseChapterQuestionsFetchError,
    ClassroomNotFound,
    SandboxEvalError,
    SandboxTestError
} from './lib/errors';

const courseApi = new CodeStopApi(`${config.gateway}/courses`);
const userApi = new CodeStopApi(`${config.gateway}/users`);
const sandboxApi = new CodeStopApi(`${config.gateway}/sandbox`, { retry: 3});
const classroomApi = new CodeStopApi(`${config.gateway}/classroom`);
const databaseApi = new CodeStopApi(`${config.gateway}/database`);

export const getUserRole = () => 
    userApi.get("/userRole")
        .then((response: any) => response.data)
        .catch(e => { throw new UserRoleFetchError(e) });

export const getUser = async () => {

    try {
        const response = await userApi.get("/me");
        const userId = response.data.userId;

        if (userId) {
            const response = await userApi.get(`/user/${userId}`);
            return response.data;
        }

    } catch (e) {
        throw new UserNotFound(e)
    }

}

// TODO We need to find course by ID. Since we're on the reader already, we should have an ID.
export const getCourseByKey = (courseKey: string) => courseApi.get(`/course-key/${courseKey}/course`)
    .then((response: any) => response.data.data)
    .catch(e => { throw new CourseNotFound(e) });

export const courseKeyExists = (courseKey: string) => courseApi.get(`/course-key/${courseKey}/exists`)
    .then((response: any) => response.data.data)
    .catch(e => { throw new CourseError(e) });

export const getCourse = (courseId: string) => courseApi.get(`/course/${courseId}`)
    .then((response: any) => response.data.data)
    .catch(e => { throw new CourseNotFound(e) });

    
export const getCourseSummary = (courseId: string, classroomId: string) => courseApi.get(`/course/${courseId}/summary`, { params: { classroomId }})
    .then((response: any) => response.data)
    .catch(e => { throw new CourseSummaryFetchError(e) });

export const getClassroom = (classroomId: string) =>
    classroomApi.get(`/classrooms/${classroomId}`)
        .then((response: any) => {
            return response.data;
        })
        .catch((error:any) => {
            throw error;
        });

export const getQuestions = (courseId: string, chapter: string) => 
    courseApi.get(`/course/${courseId}/chapter/questions`, { params: { chapter }})
        .then((response: any) => response.data.data)
        .then((data: any) => map(data.questions, (n: any) => ({ 
            ...n, 
            parent: fromJS(n.parent),
            answered: false,
            skip: false
        })))
        .then((data: any) => OrderedMap(keyBy(data, (n: any) => n.id)))
        .then((data: any) => data.mapEntries(([k, v]: any) => [k, Map(v)]))
        .catch(e => { throw new CourseChapterQuestionsFetchError(e)});

export const getIntroduction = (courseId: string) =>
    courseApi.get(`/course/${courseId}/introduction`)
        .then((response: any) => response.data.data)
        .then((data: any) => map(data, (n: any, k: any) => ({ 
            ...n, 
            parent: fromJS({}),
            answered: false,
            skip: false,
            id: k
        })))
        .then((data: any) => OrderedMap(keyBy(data, (n: any) => n.id)))
        .then((data: any) => data.mapEntries(([k, v]: any) => [k, Map(v)]))
        .catch(e => { throw new CourseChapterQuestionsFetchError(e)});

export const getFact = (courseId: string, chapter: string) =>
    courseApi.get(`/course/${courseId}/chapter/fact`, { params: { chapter }})
        .then((response: any) => response.data)
        .catch(e => { throw new CourseChapterQuestionsFetchError(e)});

export const getCourseMapping = (courseId: string) => courseApi.get(`/course/${courseId}/mapping`)
    .then((response: any) => response.data)

export const postEval = (request: any) => {
    if (request.param === 'sql') {
        return databaseApi.post('/query', request)
            .then((response: any) => response.data)
            .catch(e => { throw new SandboxEvalError(e) });
    } else {
        return sandboxApi.post('/eval', request)
            .then((response: any) => response.data)
            .catch(e => { throw new SandboxEvalError(e) });
    }
};

/* TODO: We need to separate the service APIs
 * We can call it services
 */
export const getClassroomByKey = async (classroomKey: string) => {

    try {
        const filter = {
            "filter" : {
                "include" : ["instructor","settings"],
                "where" : {
                    "and" : [
                        { "classroomKey" : classroomKey },
                        { "deletedAt": null }
                    ]
                }
            }
        }
    
        const response = await classroomApi.get('/classroomKey/findOne', { params: filter });
        return response.data;

    } catch(e) {
        throw new ClassroomNotFound(e)
    }    
}

export const postTest = (request: any) => {
    if (request.param === 'sql') {
        return databaseApi.post('/test', request)
            .then((response: any) => response.data)
            .catch(e => { throw new SandboxTestError(e) });
    } else {
        return sandboxApi.post(`/test`, request)
            .then((response: any) => response.data)
            .catch(e => { throw new SandboxTestError(e) });
    }
}

/**
 * User Token must always be available in the request()
 * @param {Object} data { courseName, questionId, userAnswer }
 */
export const postUserAnswer = (data: any) => 
    userApi.post(`/answers`, data)
        .then((response: any) => response.data)

export const deleteUserAnswersByChapter = (classroomId: string, courseId: string, chapter: string, skip: boolean = false) =>
    userApi.delete('/answers', { data: { classroomId, courseId, chapter, skip } })
        .then((response: any) => response.data);

export const getUserAnswers = (courseId: string, classroomId: string) =>
    userApi.get('/answers', { params: { courseId, classroomId }})
        .then((response: any) => response.data)
        .then((data: any) =>  fromJS(keyBy(data, (n: any) => n.questionId)) )
        .catch(e => { throw new UserAnswerFetchError(e) });

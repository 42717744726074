export const getCourse = (state: any) => state.main.get('course');
export const getCourseEngine = (state: any) => getCourse(state).get('engine');
export const getCourseName = (state: any) => getCourse(state).get('name');
export const getCourseId = (state: any) => getCourse(state).get('courseId');
export const getCourseTitle = (state: any) => getCourse(state).get('title');
export const getSummary = (state: any) => getCourse(state).get('summary');
export const getSummaryChapter = (state: any) => getSummary(state).find((chapter:any) => chapter.get('url') === 'content/' + getChapter(state));
export const isCourseFetched = (state:any) => getCourse(state).get('isFetched');
export const getCourseVersion = (state: any) => getCourse(state).get('version');

export const getLesson = (state: any) => getSummaryChapter(state).get('text');

export const getChapter = (state: any) => getCourse(state).getIn(['chapter', 'current']);
export const getMapping = (state: any) => getCourse(state).get('mapping');
export const getQuestionCount = (state: any) => getSummary(state).reduce((sum: number, chapter: any) => sum + chapter.get('noOfQuestions'), 0);

export const getClassroom = (state: any) => state.main.get('classroom');
export const getClassroomId = (state: any) => getClassroom(state).get('classroomId');
export const getClassroomKey = (state: any) => getClassroom(state).get('classroomKey');
export const isClassroomFetched = (state: any) => getClassroom(state).get('isFetched');
export const getClassroomInstructorId = (state: any) => getClassroom(state).get('instructorId');

export const getClassroomSettingEnableShowAnswertBtn = (state: any) => getClassroom(state).get('enableShowAnswerBtn');

export const getUser = (state: any) => state.main.get('user');
export const getUserAnswers = (state: any) => getUser(state).get('answers');
export const getUserId = (state: any) => getUser(state).get('userId');

export const getUserRole = (state: any) => getUser(state).get('role');
export const userRoleIsAdmin = (state: any) => getUserRole(state).get('isAdmin');
export const userRoleIsAuthor = (state: any) => getUserRole(state).get('isAuthor');
export const userRoleIsInstructor = (state: any) => getUserRole(state).get('isInstructor');
export const userIsClassroomInstructor = (state: any) => getUser(state).get('isClassroomInstructor');
export const userIsGuest = (state: any) => getUser(state).get('isGuest');
export const userCanPreview = (state: any) => userIsClassroomInstructor(state) || userRoleIsAdmin(state) || userRoleIsAuthor(state) || userIsGuest(state);
export const userIsNotStudent = (state: any) => userIsClassroomInstructor(state) || userRoleIsAuthor(state) || userRoleIsAdmin(state) || userIsGuest(state);
export const userIsStudent = (state: any) => !userIsNotStudent(state);

export const getQuestions = (state: any) => state.main.get('questions');
export const getNextUnansweredQuestion = (state: any) => getQuestions(state).find((q: any) => !q.get('answered'));
export const getLastAnsweredQuestion = (state: any) => getQuestions(state).findLast((q: any) => q.get('answered'));

export const getNextQuestion = (state: any) => state.main.get('nextQuestion')
export const getNextUnSkippedQuestion = (state: any) => getQuestions(state).find((q: any) => !q.get('answered') && !q.get('skip'));
export const getNextSkippedQuestion = (state: any) => getQuestions(state).find((q: any) => q.get('skip'));

export const getScreen = (state: any) => state.main.get('screen');
export const getParentScreen = (state: any) => state.main.get('screen').get('parent');

export const doneOnLesson = (state: any) => state.main.getIn(['user', 'doneOnLesson']);
export const doneOnCourse = (state: any) => state.main.getIn(['user', 'doneOnCourse']);

export const incompleteLesson = (state: any) => state.main.getIn(['user', 'incompleteLesson']);

export const getQuestionStartTime = (state: any) => state.main.getIn(['user','startTime']);

export const getReaderMode = (state: any) => state.main.get('mode');
export const isPreviewMode = (state: any) => getReaderMode(state).get('isPreview');
export const getPreviewType = (state: any) => getReaderMode(state).get('previewType');
export const isClassroomPreview = (state: any) => getReaderMode(state).get('previewType') === 'classroom';
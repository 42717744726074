import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fact from '../../components/fact';
import * as Selector from './selectors';
import * as Action from './actions';

export default connect(
    state => ({
        fact: Selector.getFactContent(state),
        isOpen: Selector.isOpen(state),
        tries: Selector.tries(state)
    }),
    dispatch => ({
        resetTry: bindActionCreators(Action.resetTry, dispatch),
        setIsOpen: bindActionCreators(Action.setIsOpen, dispatch),
    })
)(Fact);
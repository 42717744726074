import { USER_NOT_FOUND } from '../codestop/codestop-types';

class UserNotFound extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'User Not Found'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = USER_NOT_FOUND;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, UserNotFound);
        }
    }
}

export default UserNotFound;
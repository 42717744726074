import * as Type from './types';
import * as Selectors from './../selectors';

export const toggleSidebar = (toggle: boolean) => async (dispatch: any, getState: any) => {
    dispatch({ type: Type.SIDEBAR_TOGGLE, payload: toggle });
};

export const showSidebar = () => (dispatch: any, getState: any) => {

    if (Selectors.doneOnLesson(getState())) {
        dispatch({ type: Type.SIDEBAR_TOGGLE, payload: false })
    }

}
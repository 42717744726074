import * as types from "./types";
import { Map } from 'immutable';

const initialState = Map({
    results: {} 
});

export default (state = initialState, action: any) => {
    switch(action.type) {
        case types.TABLE_RESULT_ADD:
            return state
                .set("results", action.payload);
        case types.TABLE_CLEAR:
            return initialState;
        default:
            return state;
    }
};
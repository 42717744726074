import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Selector from './selectors';
import * as Action from './actions';
import BrowserDisplay from '../../components/browser-display';

export default connect(
    state => ({
        toggledDisplay: Selector.getBrowserDisplayToggled(state),
        htmlOutput: Selector.getBrowserDisplayHTML(state),
        consoleOutput: Selector.getBrowserConsoleOutput(state)
    }),
    dispatch => ({
        toggleDisplay: bindActionCreators(Action.toggleDisplay, dispatch)
    })
)(BrowserDisplay);
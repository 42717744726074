import * as React from 'react';
import { Button, Intent, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import GuestLoginPrompt from "./guest-login-prompt";

interface ShowAnswerBtnProps {
    questionId: string;
    showBtnStatus: boolean;
    toggleShowBtnStatus: any;
    resetShowAnswerBtnStatus: any;
    disabled: boolean;
    classroomId: string;
    enableShowAnswerBtn: boolean;
}

export default class ShowAnswerBtn extends React.Component<ShowAnswerBtnProps, {}> {

    constructor(props: ShowAnswerBtnProps){
        super(props);
    }

    componentDidUpdate(prevProps: any, prevState:any){
        if(prevProps !== this.props){
            this.props.disabled && this.props.resetShowAnswerBtnStatus();
        }
    }

    render() {
        const { questionId, toggleShowBtnStatus, disabled, classroomId, enableShowAnswerBtn, showBtnStatus } = this.props;

        const showAnswerBtnText = showBtnStatus ? 'Hide Answer' : 'Show Answer';
        const showAnswerBtnIcon = showBtnStatus ? 'eye-off' : 'eye-on';
        const showAnswerBtn = showBtnStatus ?

                                <Button
                                    className="pull-right show-btn-answer"
                                    text={showAnswerBtnText}
                                    icon={showAnswerBtnIcon}
                                    intent={Intent.SUCCESS}
                                    onClick={() => toggleShowBtnStatus(questionId)} /> :

                                <GuestLoginPrompt
                                    className="pull-right show-btn-answer"
                                    text={showAnswerBtnText}
                                    icon={showAnswerBtnIcon}
                                    intent={Intent.SUCCESS} >

                                    <Popover
                                        interactionKind={PopoverInteractionKind.CLICK}
                                        popoverClassName="pt-popover-content-sizing show-answer-pop-over"
                                        position={Position.BOTTOM}
                                        className="pull-right"
                                        disabled={disabled}
                                    >
                                        <Button
                                            className="pull-right show-btn-answer"
                                            text={showAnswerBtnText}
                                            icon={showAnswerBtnIcon}
                                            disabled={disabled}
                                            intent={Intent.SUCCESS}/>
                                        <div>
                                            <h5>Are you sure?</h5>
                                            <p>You can view the answer to this problem. Current changes might be replaced by the answer.</p>
                                            <Button
                                                className="pt-popover-dismiss pt-fill pt-intent-success"
                                                onClick={() => toggleShowBtnStatus(questionId)} >
                                                Yes, please show me the answer
                                            </Button>
                                            <Button className="pt-popover-dismiss pt-fill pt-intent-primary">
                                                Not really, I was just kidding
                                            </Button>
                                        </div>
                                    </Popover>
                                </GuestLoginPrompt>

        return classroomId && !enableShowAnswerBtn ? '' : showAnswerBtn;
    }
}
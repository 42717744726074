import { COURSE_ERROR } from '../codestop/codestop-types';

class CourseError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'COURSE_ERROR'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = COURSE_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, CourseError);
        }
    } 
}

export default CourseError;
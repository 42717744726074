import { COURSE_CHAPTER_QUESTIONS_FETCH_ERROR } from '../codestop/codestop-types';

class CourseChapterQuestionsFetchError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Course Chapter Questions Fetch Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = COURSE_CHAPTER_QUESTIONS_FETCH_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, CourseChapterQuestionsFetchError);
        }
    } 
}

export default CourseChapterQuestionsFetchError;
import * as Type from "./types";
import * as ConsoleAction from '../console/actions';
import { Selector as ScreenSelector, Action as ScreenAction } from './../screen';
import * as Selectors from './../selectors';
import { findIndex } from 'lodash';

export const verificationStarted = () => (dispatch: any) =>
    dispatch({ type: Type.VERIFICATION_STARTED });

export const verificationDone = () => (dispatch: any) =>
    dispatch({ type: Type.VERIFICATION_DONE });

export const verificationFailed = () => (dispatch: any) =>
    dispatch({ type: Type.VERIFICATION_FAILED });

export const verificationSuccess = () => (dispatch: any) =>
    dispatch({ type: Type.VERIFICATION_SUCCESS });

export const reset = () => (dispatch: any) =>
    dispatch({
        type: Type.RESET
    });

export const setLastQuestion = (status: boolean) => (dispatch: any) =>
    dispatch({ type: Type.SET_LAST_QUESTION, payload: status });

export const updateNavigation = (currentQuestion: any) => (dispatch: any, getState: any) => {

    let navigation = {
        canNext: false,
        canPrev: false,
        canStart: false,
        canEnd: false
    }

    let index = -1;

    const state = getState();
    const questions = Selectors.getQuestions(state).toArray();

    questions.map((question: any, i: number) => {
        if (question.get('id') === currentQuestion.get('id')) {
            index = i;
        }
    })

    if (index > 0) {
        navigation.canPrev = questions[index - 1].get('answered');
        navigation.canStart = true;
    }

    if (index <= questions.length - 2) {
        navigation.canNext = questions[index + 1].get('answered') || currentQuestion.get('answered');
        navigation.canEnd = questions[questions.length - 1].get('answered');
    }

    if (index == questions.length - 1) {
        navigation.canNext = questions[index].get('answered');
    }

    dispatch({ type: Type.RESET });
    dispatch({ type: Type.UPDATE_NAVIGATION, payload: navigation });
}

export const onMove = (direction: string) => (dispatch: any, getState: any) => {

    const state = getState();
    const questions = Selectors.getQuestions(state).toArray();
    const currentQuestion = ScreenSelector.getCurrentQuestion(state);

    const index = findIndex(questions, (q, i) => {
        return questions[i].get('id') == currentQuestion.get('id');
    })

    if (index >= 0 && index <= questions.length) {
        const qIndex = direction == "next" ? index + 1 : index - 1;

        if (qIndex == questions.length) {
            dispatch(ScreenAction.initScreen());
            dispatch(setLastQuestion(true));
        }

        if (questions[qIndex]) {
            dispatch(ConsoleAction.clear());
            dispatch(ScreenAction.setScreen(questions[qIndex]));
        }
    }
}

export const onStart = () => (dispatch: any, getState: any) => {
    const state = getState();
    const questions = Selectors.getQuestions(state).toArray();
    dispatch(ConsoleAction.clear());
    dispatch(ScreenAction.setScreen(questions[0]));
}

export const onEnd = () => (dispatch: any, getState: any) => {
    const state = getState();
    const questions = Selectors.getQuestions(state).toArray();
    dispatch(ConsoleAction.clear());
    dispatch(ScreenAction.setScreen(questions[questions.length - 1]));
}

export const onJump = (questionId: any) => (dispatch: any, getState: any) => {
    const state = getState();
    const questions = Selectors.getQuestions(state).toArray();
    const question = questions.find((q: any) => q.get('id') == questionId);

    dispatch(ScreenAction.setScreen(question));
}
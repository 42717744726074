import * as React from "react";

interface FrameProps {
    body: string;
}

interface FrameState {
    body: string;
    random: number;
}

export default class Frame extends React.Component<FrameProps, FrameState> {
    constructor(props: FrameProps){
        super(props);
        this.state = Frame.getInitialState(props);
    }

    static getInitialState = (props: FrameProps) => ({
        body: props.body,
        random: 0
    })

    static getDerivedStateFromProps(nextProps: FrameProps, prevState: FrameState) {

        if (nextProps.body !== prevState.body) {
            return Frame.getInitialState(nextProps);
        }

        return null;

    }

    render() {
        const { body } = this.props;
        const buff = new Buffer(body);
        const encoded = buff.toString('base64');
        const encodedBody = "data:text/html;charset=utf-8;base64," + encoded

        if (body) {
            return (
                <iframe key={ this.state.random } frameBorder="0" className="browser-display-output" id="browser-display-iframe" src={ encodedBody }
                    sandbox="allow-forms allow-popups allow-scripts allow-same-origin">
                </iframe>
            );
        } else {
            return <div className="browser-display-output"></div>;
        }
    }
}
import { nth, last, split } from 'lodash';

export const getReaderMode = (href:string, host:string) => () => {
    const url = split(last(split(href, host)) || '', '/');
    const isPreview = nth(url, 1) == 'preview'
    const isPreviewClassroom = isPreview ? nth(url, 2) === 'classroom' : false;
    const previewBasePath = isPreviewClassroom ? '/preview/classroom'  : '/preview/course';
    const classroomKey = isPreview ? nth(url, 3) || '' : nth(url, 2) || '';
    const base = !isPreview ? '/learn' : previewBasePath;

    return {
        'classroomKey' : classroomKey,
        'isPreview' : isPreview,
        'isPreviewClassroom' : isPreviewClassroom,
        'base' : base
    }
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Component from './components';
import { Action } from './state';
import { Provider } from 'react-redux';
import { configure } from './store';
import { getReaderMode } from './lib/reader-mode';
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import './responsive.css';
import './index.css';

const store = configure();
if (process.env.NODE_ENV !== 'production') {
    store.subscribe(() => {
        //console.log((store.getState() as any));
        //console.log((store.getState() as any).main.toJS());
    });
}

const readerMode = store.dispatch(getReaderMode(window.location.href, window.location.host));
const classroomKey = readerMode.classroomKey;
const isPreview = readerMode.isPreview;
const isPreviewClassroom = readerMode.isPreviewClassroom;
const base = readerMode.base;

store.dispatch(Action.bootstrap(classroomKey, isPreview, isPreviewClassroom))
    .then(() =>
        ReactDOM.render(
            <Provider store={store}>
                <Router basename={base}>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>CodeStop</title>
                    </Helmet>
                    <Switch>
                        <Route exact path="/:classroomKey/content/**" component={Component.Main} />
                        <Redirect from="/:classroomKey/" to={`/${classroomKey}/content/INTRODUCTION.html`} />
                        <Route component={Component.NotFound} />
                    </Switch>
                </div>
                </Router>
            </Provider>,
            document.getElementById('container')
        )
    );

unregister();
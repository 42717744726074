import * as Type from './types';
import { Map } from 'immutable';

const initialState = Map({
    isPending: false,
    isDone: false,
    isLastQuestion: false
});

const statusState = Map({
    isFailed: false,
    isSuccess: false
})

export default (state = initialState, action: any) => {
    switch (action.type) {
        case Type.VERIFICATION_STARTED:
            return state.merge(initialState.set('isPending', true));
        case Type.VERIFICATION_DONE:
            return state.merge(initialState.set('isDone', true));
        case Type.VERIFICATION_FAILED:
            return state.merge(statusState.set('isFailed', true));
        case Type.VERIFICATION_SUCCESS:
            return state.merge(statusState.set('isSuccess', true));
        case Type.UPDATE_NAVIGATION:
            return state.merge(action.payload);
        case Type.SET_LAST_QUESTION:
            return state.set('isLastQuestion', action.payload);
        case Type.RESET:
            return initialState;
        default:
            return state;
    }
}
import { CODESTOP_TIMEOUT_ERROR } from '../codestop/codestop-types';

/**
 * This is the generic timeout error. Ideally, we would want each service to have
 * it's own timeout error so we can have better flexibility on how to act on it.
 */
class TimeoutError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = typeof args === 'string' ? args : args.message;

        super(message);
        this.name = CODESTOP_TIMEOUT_ERROR;
        this.code = 'ECONNABORTED';

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, TimeoutError);
        }
    } 
}

export default TimeoutError;
export const HTML   = "html";
export const CSS    = "css";
export const JS     = "javascript";
export const PHP    = "php";
export const SQL    = "sql";

export const isHTML = (mode:String) => mode === HTML;

export const isCSS = (mode:String) => mode === CSS;

export const isJS = (mode:String) => mode === JS;

export const isPHP = (mode:String) => mode === PHP;

export const isSQL = (mode:String) => mode === SQL;

export const hasConsole = (mode:String) => isPHP(mode) || isJS(mode);

export const hasBrowserDisplay = (mode: String) => isHTML(mode) || isCSS(mode);

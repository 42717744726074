import * as Type from './types';
import * as Selectors from './../selectors';
import * as ScreenSelector from './selectors';
import { Action as ConsoleAction } from '../console';
import { Action as TipsAction } from '../tips';
import { Action as QuestionNavigationAction } from '../question-navigation';
import { Action as ShowAnswerBtnAction } from '../show-answer-btn';
import { Action as CommonAction } from "../../state";
import { Action as SQLTableAction } from '../sql-table';
import { Action as SQLConsoleAction } from '../sql-console';
import { Action as BrowserDisplayAction } from '../browser-display';

export const clearScreens = () => (dispatch: any) => {
    dispatch({ type: Type.CLEAR_SCREENS });
}

export const clearOutputDisplay = () => (dispatch: any) => {
    dispatch(ConsoleAction.clear());
    dispatch(SQLConsoleAction.clear());
    dispatch(SQLTableAction.clearTable());
    dispatch(BrowserDisplayAction.clearBrowser());
}

export const setScreen = (question: any) => (dispatch: any, getState: any) => {
    const currentQuestionContainerId = ScreenSelector.getCurrentQuestion(getState()).getIn(['parent', 'id']);
    const nextQuestionContainerId = question.getIn(['parent', 'id']);

    if(currentQuestionContainerId && nextQuestionContainerId) {
        if (currentQuestionContainerId != nextQuestionContainerId) {
            dispatch(clearOutputDisplay());
        }
    } else {
        dispatch(clearOutputDisplay());
    }

    dispatch({ type: Type.SCREEN_LOADED, payload: question });

    dispatch({
      type: Type.PARENT_SCREEN_LOADED,
      payload: question.get("parent")
    });

    dispatch(TipsAction.clearTips());
    dispatch(CommonAction.setQuestionStartTime());
    dispatch(ShowAnswerBtnAction.resetShowAnswerBtnStatus());
    dispatch(QuestionNavigationAction.updateNavigation(question));
};

// TODO: implement nextQuestion, previousQuestion selector
// jumping to question: the question navigation component will containt the IDs

export const initScreen = () => async (dispatch: any, getState: any) => {

    const nextUnAnsweredQuestion = Selectors.getNextUnansweredQuestion(getState());

    if(nextUnAnsweredQuestion === undefined) {
        dispatch(clearScreens());
        return dispatch(CommonAction.endLesson());
    }

    const nextUnSkippedQuestion = Selectors.getNextUnSkippedQuestion(getState());

    await dispatch(CommonAction.setNextQuestion());

    if (nextUnSkippedQuestion === undefined) {

        dispatch(clearScreens());
        dispatch(QuestionNavigationAction.reset());

        const nextSkippedQuestion = Selectors.getNextSkippedQuestion(getState());
        const nextQuestion = Selectors.getNextQuestion(getState());

        if(nextQuestion === undefined) {
            return dispatch(CommonAction.incompleteLesson());
        } else {
            dispatch(setScreen(nextSkippedQuestion));
            dispatch(QuestionNavigationAction.updateNavigation(nextSkippedQuestion));
        }

    } else {
        dispatch(setScreen(nextUnSkippedQuestion));
        dispatch(QuestionNavigationAction.updateNavigation(nextUnSkippedQuestion));
    }

}

export const nextScreen = () => async (dispatch: any, getState: any) => {

    await dispatch(CommonAction.setNextQuestion());

    const nextQuestion = await Selectors.getNextQuestion(getState());

    if(nextQuestion === undefined) {
        dispatch(initScreen());
    } else {
        dispatch(setScreen(nextQuestion));
    }

}
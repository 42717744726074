import * as React from 'react';
import { connect } from "react-redux";
import * as Selectors from "./../app/selectors";
import { Button, Intent, Alert } from "@blueprintjs/core";
import { platform as platformUrl } from "../lib/platform-base-url";

interface GuestLoginPromptProps {
    isGuest: boolean;
    text: string;
    icon: any;
    intent: any;
    className?: string;
}

export interface GuestLoginPromptState {
    isOpen: boolean;
}

class GuestLoginPrompt extends React.Component<GuestLoginPromptProps, GuestLoginPromptState> {

    constructor(props: GuestLoginPromptProps){
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        isOpen: false
    });

    onClickHandler = () => this.setState({ isOpen: true });
    onCancelHandler = () => this.setState({ isOpen: false });

    onConfirmHandler = () => {
        window.location.assign(platformUrl("/register"))
    }

    render() {
        const { isGuest, text, icon, intent, className } = this.props;
        const { isOpen } = this.state;

        const classes = className ? className : ""

        if (isGuest) {
            return (
                    <div>
                        <Button
                            className={classes}
                            text={text}
                            icon={icon}
                            intent={intent}
                            onClick={this.onClickHandler} />
                        
                        <Alert
                                cancelButtonText="Close"
                                confirmButtonText="Click here to Sign-up"
                                icon="user"
                                intent={Intent.DANGER}
                                isOpen={isOpen}
                                onCancel={this.onCancelHandler}
                                onConfirm={this.onConfirmHandler}
                            >
                            <p>
                                <h5>Hi.</h5>
                                <p>Want to take this course? Sign up now for FREE!</p>
                            </p>
                        </Alert>
                    </div>
            )
        }

        return this.props.children;
    }
}

export default connect((state: any) => ({
        isGuest: Selectors.userIsGuest(state)
    })
)(GuestLoginPrompt);
import { USER_SHOW_ANSWER_POST_ERROR } from '../codestop/codestop-types';

class UserShowAnswerPostError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'User Show Answer Post Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = USER_SHOW_ANSWER_POST_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, UserShowAnswerPostError);
        }
    }
}

export default UserShowAnswerPostError;
import config from '../../config';
import CodeStopApi from '../codestop/codestop-api';
import { enqueue } from '../queue';
import { errorHandler } from '../error-handler';
import { UserShowAnswerPostError, UserAnswerPostError } from '../errors';

const achieveApi = new CodeStopApi(`${config.gateway}/achieve`);

export interface UserAnswerData {
    userId: number;
    classroomId?: string;
    courseId: string;
    questionId: string;
    chapter: string;
    userAnswer: string;
    isCorrect: boolean;
    timeDuration: number;
};

export interface ShowAnswerData {
    userId: number;
    classroomId?: string;
    courseId: string;
    questionId: string;
    chapter: string;
    date: string;
};

export const pushUserAnswer = (data: UserAnswerData) => async(dispatch: any) => {
    try {
        await achieveApi.post('/attempts', data);
    }
    catch (err) {
        dispatch(errorHandler(new UserAnswerPostError(err)));
    }
}

export const pushShowAnswer = (data: ShowAnswerData) => async(dispatch: any) => {
    try {
        await achieveApi.post('/show-answers', data);
    }
    catch (err) {
        dispatch(errorHandler(new UserShowAnswerPostError(err)));
    }
}

export const postUserAnswer = (data: UserAnswerData) => {
    enqueue(pushUserAnswer, data);
}

export const postShowAnswer = (data: ShowAnswerData) => {
    enqueue(pushShowAnswer, data);
}
import * as React from 'react';
import { findIndex } from 'lodash';
import { Classes, Tree, ITreeNode, Tag, Intent } from "@blueprintjs/core";

interface SidebarProps {
    dispatch: any;
    history: any;
    course: any;
    summary: any;
    questions: any;
    toggle: boolean;
    classroomKey: string;
    lesson: string;
}

interface SidebarState {
    lesson: string;
    nodes: ITreeNode[];
    toggleClass: any;
}

export default class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);
        this.state = Sidebar.getInitialState(props);
    }

    static getInitialState = (props: SidebarProps) => ({
        lesson: props.lesson,
        nodes: Sidebar.buildNodes(props),
        toggleClass: ''
    });

    static getDerivedStateFromProps(nextProps: SidebarProps, prevState: SidebarState) {

        if (nextProps.lesson !== prevState.lesson) {
            return Sidebar.getInitialState(nextProps);
        }

        return {
            nodes: Sidebar.buildNodes(nextProps),
            toggleClass: nextProps.toggle ? 's-sidebar__toggled' : ''
        }

    }

    static buildNodes = (props: any) => {
        const isOnPage = (link: any) => link.get('url') === `content/${props.match.params[0]}`;
        const questionsLeftLabel = (link: any) => link.get('noOfQuestionsLeft') > 0
            ? <span className={isOnPage(link) ? 'pt-monospace-text' : 'pt-monospace-text pt-text-muted'}>
                { (link.get('noOfQuestions') - link.get('noOfQuestionsLeft')) }/{ link.get('noOfQuestions') }
            </span>
            : <Tag intent={Intent.SUCCESS} >Done</Tag>;
        const isDone = (link:any) => link.get('noOfQuestionsLeft') == 0 ? 'done' : '';
        const getChildNodes = (link:any) => {

            const sections:any = [];
            const title = link.get('url').replace('html','json');

            props.questions.map((question:any, i:number)=> {
                
                const entry = question.getIn(['parent','parent','parent']);

                if (entry !== 'undefined') {

                    const chapter = question.getIn(['parent','parent','parent','chapter']);

                    if(title == chapter){

                        const content = question.getIn(['parent','parent','content']);
                        const entryItem = {
                            id: i,
                            label: content.replace(/(<h3>|<\/h3>)/g, ""),
                            isExpanded: true,
                        }

                        const index = findIndex(sections, {'label': entryItem.label});

                        const type = question.getIn(['parent','parent','type']);
                        if(type == 'SECTION' && index<0) {
                            entryItem['id'] = i;
                            sections.push(entryItem);
                        }
                    }

                }
            });

            return sections;
        }

        const hasChild = (link:any) => {
            if( link.get('url') == url.slice(url.indexOf('content'),url.length) && getChildNodes(link).length ) {
                return true;
            } else {
                return false;
            }
        }

        const url = window.location.pathname;
        const nodes = props.summary.map((link: any, i: number) => ({
            id: i,
            label: link.get('text'),
            url: link.get('url'),
            secondaryLabel: questionsLeftLabel(link),
            isSelected: isOnPage(link),
            icon: 'tick-circle',
            childNodes: getChildNodes(link),
            isExpanded: false, //hasChild(link),
            hasCaret: hasChild(link),
            className: isDone(link)
        }));

        return nodes.toJS();
    };

    render() {
        const { history, course, classroomKey } = this.props;
        const { toggleClass } = this.state;

        const uri = classroomKey ? classroomKey : course.get('name');

        return (
            <div className={"s-layout__sidebar " + toggleClass }>
                <nav className={"s-sidebar__nav " + toggleClass }>
                    <div className="pt-tabs pt-dark">
                        <ul className="pt-tab-list .modifier" role="tablist">
                            <li className="pt-tab" role="tab" aria-selected="true">Table of Contents</li>
                        </ul>
                        <div className="pt-tab-panel pt-dark" role="tabpanel">
                            <Tree
                                contents={this.state.nodes}
                                onNodeClick={(node: any, num, e) => node.url === undefined ? null : history.push(`/${uri}/${node.url}`)}
                                onNodeCollapse={this.handleNodeCollapse}
                                onNodeExpand={this.handleNodeExpand}
                                className={Classes.ELEVATION_0}
                            />
                        </div>
                    </div>
                </nav>
            </div>
        )
    }

    private handleNodeCollapse = (nodeData: ITreeNode) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
    };

    private handleNodeExpand = (nodeData: ITreeNode) => {
        nodeData.isExpanded = true;
        this.setState(this.state);
    };
}

import * as types from "./types";
import { Map } from 'immutable';

const initialState = Map({
    toggled: false,
    html: '',
    console: ''
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.TOGGLE_DISPLAY:
      return state
        .set('toggled', action.payload )
    case types.DISPLAY_HTML:
      return state
        .set('html', action.html)
        .set('console', action.console)
    case types.CLEAR_BROWSER:
      return state
        .set('html', '')
        .set('console', '')
    default:
      return state;
  }
};
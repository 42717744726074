import * as Type from './types';
import { Map } from 'immutable';

const initialState = Map({
    toggle: false
});

export default (state = initialState, action: any) => {
    switch(action.type) {
        case Type.SIDEBAR_TOGGLE:
            return initialState.set('toggle', action.payload);
        default:
            return state;
    }
}
import { COURSE_NOT_FOUND } from '../codestop/codestop-types';

class CourseNotFound extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'COURSE_NOT_FOUND'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args ? args.code : 400;
        this.name = COURSE_NOT_FOUND;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, CourseNotFound);
        }
    } 
}

export default CourseNotFound;
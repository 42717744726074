import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../app/selectors';
import { platform as platformUrl } from "../lib/platform-base-url";


interface Props {
    displayArrow?: boolean;
    isGuest: boolean;
    classNames: string;
}

class PlatformLink extends React.Component<Props> {

    render() {

        const { isGuest, classNames } = this.props;

        if (isGuest) {

            return <div>
                <a className={classNames} href={ platformUrl('/login') }> Log In</a>
                <a className={classNames} href={ platformUrl('/register') }> Sign Up</a>
            </div>

        } else {
            return this.props.children;
        }

    }
}

export default connect(
    (state: any) => ({
        isGuest: selectors.userIsGuest(state)
    })
)(PlatformLink)

import config from '../../config';
import CodeStopApi from '../codestop/codestop-api';
import { enqueue } from '../queue';
import { errorHandler } from '../error-handler';
import { UserCourseAccessError, UserCourseStartedError } from '../errors';

const activityTracker = new CodeStopApi(`${config.gateway}/activity-tracker`);

/* Course Access Activity Log */
export interface CourseAccessData {
    userId: number;
    classroomId: string;
    courseId: string;
};

export const postCourseAccess = (data: CourseAccessData) => async(dispatch: any) => {
    try {
        const date = new Date();
        const where = {
            "where" : {
                "and" : [
                    { "date": { "gte": date.toLocaleDateString() }},
                    { "userId": data.userId },
                    { "classroomId": data.classroomId },
                    { "courseId": data.courseId },
                ]
            }
        }

        data['date'] = date;

        await activityTracker.post('/course-access/upsertWithWhere', data, { params: where });
    }
    catch (err) {
        dispatch(errorHandler(new UserCourseAccessError(err)));
    }
}

export const recordCourseAccess = (data: CourseAccessData) => {
    enqueue(postCourseAccess, data);
}

/* Course Started Activity Log */
export interface CourseStartedData {
    userId: number;
    classroomId: string;
    courseId: string;
};

export const recordCourseStarted = async (data: CourseStartedData) => {
    if( !await hasCourseStarted(data) ) {
        enqueue(postCourseStarted, data);
    }
}

export const hasCourseStarted = (data: CourseStartedData) => {

    const where = {
        "where" : {
            "and" : [
                { "userId": data.userId },
                { "classroomId": data.classroomId },
                { "courseId": data.courseId },
            ]
        }
    }

    return activityTracker.get("/course-started/count", { params: where })
            .then((response: any) => response.data.count !== 0 );
}

export const postCourseStarted = (data: CourseStartedData) => async(dispatch: any) => {
    try {
        await activityTracker.post('/course-started', data);
    }
    catch (err) {
        dispatch(errorHandler(new UserCourseStartedError()));
    }
}
import * as React from 'react';
import { connect } from 'react-redux';
import * as Selectors from '../app/selectors';
import { Route } from 'react-router-dom';
import { default as Navigation } from './navigation';
import { default as CheckDisplay } from './check-display';
import { Chapter } from './../app';
import { Sidebar } from './../app';
import * as Page from './error-pages';

interface Props {
    isCourseFetched: any
}


class Main extends React.Component<Props, {}> {
    constructor(props:any) {
      super(props);
    }

    render() {
        const { isCourseFetched } = this.props;

        if(isCourseFetched){
            return <Page.NotFound>
                    <CheckDisplay>
                        <header><Navigation /></header>
                        <div className="s-layout">
                            <Route exact path="/:courseName/content/**" component={Chapter} />
                            <Route exact path="/:courseName/content/**" component={Sidebar} />
                        </div>
                    </CheckDisplay>
                </Page.NotFound>
        } else {
            return <Page.NotFound></Page.NotFound>
        }

    }
}

export default connect(
    (state:any) => ({
        isCourseFetched: Selectors.isCourseFetched(state)
    })
)(Main);
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Selector from './selectors';
import * as Action from './actions';
import * as Selectors from './../selectors';
import ShowAnswerBtn from '../../components/show-answer-btn';

export default connect(
    state => ({
        showBtnStatus: Selector.getShowAnswerBtnStatus(state),
        classroomId: Selectors.getClassroomId(state),
        enableShowAnswerBtn: Selectors.getClassroomSettingEnableShowAnswertBtn(state)
    }),
    dispatch => ({
        toggleShowBtnStatus: bindActionCreators(Action.toggleShowAnswerBtnStatus, dispatch),
        resetShowAnswerBtnStatus: bindActionCreators(Action.resetShowAnswerBtnStatus, dispatch)
    })
)(ShowAnswerBtn);
import * as selectors from './app/selectors';
import { Map, List, OrderedMap, fromJS } from 'immutable';
import { Action as FactAction } from './app/fact';
import { Action as ScreenAction, Selector as ScreenSelector } from './app/screen';
import { findIndex } from 'lodash';
import * as Type from './lib/codestop/codestop-types';
import { ClassroomAccessDenied, ClassroomNotFound, CourseNotFound } from './lib/errors';
import { isPOSTGRESQL } from './lib/codestop/codestop-engines';

// types
const COURSE_FETCHED = 'COURSE_FETCHED';
const SUMMARY_FETCHED = 'SUMMARY_FETCHED';
const QUESTIONS_FETCHED = 'QUESTIONS_FETCHED';
const CLASSROOM_FETCHED = 'CLASSROOM_FETCHED';
const MAPPING_FETCHED = 'MAPPING_FETCHED';

const SET_USER_ROLE_ADMIN = 'SET_USER_ROLE_ADMIN';
const SET_USER_ROLE_AUTHOR = 'SET_USER_ROLE_AUTHOR';
const SET_USER_ROLE_INSTRUCTOR = 'SET_USER_ROLE_INSTRUCTOR';
const SET_IS_CLASSROOM_INSTRUCTOR = 'SET_IS_CLASSROOM_INSTRUCTOR'
const SET_USER_IS_GUEST = 'SET_USER_IS_GUEST'

const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_AUTHOR = 'ROLE_AUTHOR';
const ROLE_INSTRUCTOR = 'ROLE_INSTRUCTOR';

const USER_ANSWER_POSTED = 'USER_ANSWER_POSTED';
const USER_ANSWERS_FETCHED = 'USER_ANSWERS_FETCHED';
const USER_ANSWER_SKIPPED = 'USER_ANSWER_SKIPPED';

const DONE_ON_LESSON = 'DONE_ON_LESSON';
const DONE_ON_COURSE = 'DONE_ON_COURSE';
const CAN_MOVE_NEXT_CHAPTER = 'CAN_MOVE_NEXT_CHAPTER';

const INCOMPLETE_LESSON = 'INCOMPLETE_LESSON';

const CHAPTER_FETCHED = 'CHAPTER_FETCHED';
const CHAPTER_RESETTED = 'CHAPTER_RESETTED';

const VERIFYING_QUESTION_STARTED = 'VERIFYING_QUESTION_STARTED';
const VERIFYING_QUESTION_DONE = 'VERIFYING_QUESTION_DONE';

const SET_QUESTION_START_TIME = 'SET_QUESTION_START_TIME';
const SET_COURSE_ID = 'SET_COURSE_ID';

const SET_NEXT_QUESTION = 'SET_NEXT_QUESTION';

//PREVIEW MODE
const SET_MODE = 'SET_MODE';

const SET_USER_ID = 'SET_USER_ID';

const verifyingQuestionStarted = () => (dispatch: any, getState: any, { topbar }: any) => topbar.show();
const verifyingQuestionDone = () => (dispatch: any, getState: any, { topbar }: any) => topbar.hide();

const requestStarted = () => async (dispatch: any) => dispatch({ type: Type.REQUEST_STARTED });
const requestDone = () => async (dispatch: any) => dispatch({ type: Type.REQUEST_DONE });

const bootstrap = (classroomKey: string, isPreview: boolean, isPreviewClassroom: boolean) => async (dispatch: any, getState: any, { api, errorHandler }: any) => {

    try {
        dispatch(requestStarted());

        if (!classroomKey) {
            throw new Error("Cannot bootstrap reader");
        }

        dispatch({ type: SET_MODE, payload: { isPreview, previewType: isPreviewClassroom ? 'classroom' : 'course' }});

        await dispatch(getUser());

        if (isPreview) {
            if (isPreviewClassroom) {
                await dispatch(bootstrapClassroom(classroomKey));

                // validate if the user is the instructor of this classroom.
                await dispatch(userIsInstructor());

                if (!selectors.userIsClassroomInstructor(getState())) {
                    throw new ClassroomAccessDenied();
                }
            } else {
                await dispatch(fetchCourseByKey(classroomKey));
            }

            if (!selectors.userCanPreview(getState()) ) {
                const location = isPreviewClassroom 
                    ? window.location.href.replace('/preview/classroom', '/learn')
                    : window.location.href.replace('/preview/course', '/learn');
                
                window.location.assign(location);
            }

            await dispatch(fetchSummary());

            if(isPOSTGRESQL(selectors.getCourseEngine(getState()))) {
                await dispatch(fetchCourseMapping(selectors.getCourseId(getState())));
            }
        } else {

            if(selectors.userIsGuest(getState())) {
                throw new ClassroomAccessDenied();
            }

            await dispatch(isIndividualLearning(classroomKey))
                ? await dispatch(bootstrapIndividualLearning(classroomKey))
                : await dispatch(bootstrapClassroom(classroomKey));

            await dispatch(fetchSummary());

            if (await dispatch(isCourseCompleted())) {
                dispatch(endCourse());
            } else {

                if (selectors.userIsStudent(getState())) {
                    dispatch(recordCourseAccess());
                }
                
                if(isPOSTGRESQL(selectors.getCourseEngine(getState()))) {
                    await dispatch(fetchCourseMapping(selectors.getCourseId(getState())));
                }
            }
        }

        dispatch(requestDone());

    } catch(err) {
        dispatch(errorHandler(err));
    }
};

const getUser = () => async(dispatch: any, getState: any, { api, errorHandler }: any) => {

    const user = await api.getUser();

    if(user) {

        const { userId, roles } = user;

        dispatch({ type: SET_USER_ID, payload: userId });

        if(roles.includes(ROLE_ADMIN)){
            dispatch({ type: SET_USER_ROLE_ADMIN });
        }

        if(roles.includes(ROLE_AUTHOR)){
            dispatch({ type: SET_USER_ROLE_AUTHOR });
        }

        if(roles.includes(ROLE_INSTRUCTOR)){
            dispatch({ type: SET_USER_ROLE_INSTRUCTOR });
        }

    } else {
        dispatch({ type: SET_USER_IS_GUEST });
    }
};

const userIsInstructor = () => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    const isRoleInstructor = selectors.userRoleIsInstructor(getState());
    const isInstructor = selectors.getUserId(getState()) == selectors.getClassroomInstructorId(getState());

    if (isRoleInstructor && isInstructor) {
        dispatch({ type: SET_IS_CLASSROOM_INSTRUCTOR });
    }
};

const isIndividualLearning = (courseKey: string) => async (dispatch: any, getState: any, { api }: any) => {
    const exists = await api.courseKeyExists(courseKey);

    return exists.exists;
};

const bootstrapIndividualLearning = (courseKey: string) => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    await dispatch(fetchCourseByKey(courseKey));
};

const bootstrapClassroom = (classroomKey: string) => async (dispatch:any, getState:any, { errorHandler }:any) => {
    await dispatch(fetchClassroom(classroomKey));
    await dispatch(fetchCourse(selectors.getCourseId(getState())));
};

const fetchCourseByKey = (courseKey: string) => async (dispatch: any, getState: any, {api, errorHandler }: any) => {
    dispatch(requestStarted());

    const course = await api.getCourseByKey(courseKey);
    dispatch({ type: COURSE_FETCHED, payload: course });
    if (!selectors.isCourseFetched(getState())) {
        throw new CourseNotFound();
    } 

    dispatch(fetchUserAnswers(course.id));

    dispatch(requestDone());
};

const fetchClassroom = (classroomKey: string) => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    dispatch(requestStarted());

    const classroom = await api.getClassroomByKey(classroomKey);
    await dispatch({ type: CLASSROOM_FETCHED, payload: classroom });
    await dispatch({ type: SET_COURSE_ID, payload: classroom.courseNewId });

    if (!selectors.isClassroomFetched(getState())) {
        throw new ClassroomNotFound();
    }

    dispatch(requestDone());
};

const fetchCourse = (courseId: string) => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    dispatch(requestStarted());
    
    const course = await api.getCourse(courseId);
    dispatch({ type: COURSE_FETCHED, payload: course });
    if (!selectors.isCourseFetched(getState())) {
        throw new CourseNotFound();
    } 

    dispatch(fetchUserAnswers(course.id));

    dispatch(requestDone());
};

// TODO We need a make this request optional. A course must know if it has mapping.
const fetchCourseMapping = (courseId: string) => async (dispatch: any, getState: any, { api }: any) => {
    dispatch(requestStarted());
    try {
        const mapping = await api.getCourseMapping(courseId);
        await dispatch({ type: MAPPING_FETCHED, payload: mapping.data.mapping });
        dispatch(requestDone());
    } catch (err) {
        //ignore mapping error for now.
    }
};

const recordCourseAccess = () => async (dispatch: any, getState: any, { errorHandler, Type, ActivityTrackerApi }: any) => {
    dispatch(requestStarted());
    try {
        const payload = {
            userId: selectors.getUserId(getState()),
            classroomId: selectors.getClassroomId(getState()),
            courseId: selectors.getCourseId(getState())
        };

        ActivityTrackerApi.recordCourseStarted(payload);
        ActivityTrackerApi.recordCourseAccess(payload);
        dispatch(requestDone());
    } catch (err) {
        dispatch(errorHandler(err, Type.ACTIVITY_TRACKER));
    }
};

const markAsAnswered = (questionId: any, userAnswer: any, skip: any = null) => async (dispatch: any, getState: any, { api, errorHandler, Type }: any) => {
    dispatch(requestStarted());
    try {
        const classroomId = selectors.getClassroomId(getState()) ? selectors.getClassroomId(getState()) : null;
        const courseId = selectors.getCourseId(getState());
        const chapter = selectors.getChapter(getState());
        const lesson = selectors.getLesson(getState());
        const data = await api.postUserAnswer({ classroomId, courseId, questionId, userAnswer, chapter, skip, lesson });

        skip ? dispatch({ type: USER_ANSWER_SKIPPED, payload: data }) :
                dispatch({ type: USER_ANSWER_POSTED, payload: data });

        await dispatch(fetchSummary());

        skip && dispatch(ScreenAction.initScreen());

        dispatch(requestDone());
    } catch (err) {
        dispatch(errorHandler(err, Type.USER_ANSWER));
    }
};

const fetchUserAnswers = (courseId: string) => async (dispatch: any, getState: any, { api, queue, errorHandler, Type }: any) => {

    const userId = selectors.getUserId(getState());

    if(userId){
        dispatch(requestStarted());
    
        dispatch(queue.init());
        const classroomId = selectors.getClassroomId(getState());
        const data = await api.getUserAnswers(courseId, classroomId);
        dispatch({ type: USER_ANSWERS_FETCHED, payload: data });

        dispatch(requestDone());
    }

};
    
const startChapter = (chapter: string) => async (dispatch: any, getState: any, { api }: any) => {
    dispatch(requestStarted());

    const courseId = selectors.getCourseId(getState());
    await dispatch(FactAction.fetchFact(courseId, chapter));
    dispatch({ type: CHAPTER_FETCHED, payload: chapter });
    dispatch(ScreenAction.clearScreens());
    dispatch({ type: DONE_ON_LESSON, payload: false });
    dispatch({ type: CAN_MOVE_NEXT_CHAPTER, payload: false });

    dispatch(requestDone());
};

const endLesson = () => (dispatch: any, getState: any, { api }: any) => {
    dispatch({ type: INCOMPLETE_LESSON, payload: false });
    dispatch({ type: DONE_ON_LESSON, payload: true });
};

const incompleteLesson = () => (dispatch: any, getState: any, { api }: any) => {
    dispatch({ type: INCOMPLETE_LESSON, payload: true });
};

const endCourse = () =>  (dispatch: any, getState: any, { api , toaster }: any) => {
    toaster.notifyMessage("Congratulations on completing this course. Your badge is making its way through virtual space. We'll let you know when it gets there.");
        // on browser reload we need to re-display the parent screen
    dispatch({ type: DONE_ON_COURSE, payload: true }); 
};

const retake = () => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    dispatch(requestStarted());
    try {
        const classroomId = selectors.getClassroomId(getState());
        const courseId = selectors.getCourseId(getState());
        const chapter = selectors.getChapter(getState());
        await api.deleteUserAnswersByChapter(
            classroomId,
            courseId,
            chapter
        );

        await dispatch(startChapter(chapter));
        dispatch({ type: CHAPTER_RESETTED, payload: chapter });
        await dispatch(fetchSummary());
        dispatch(ScreenAction.initScreen());
        dispatch(requestDone());
    } catch (err) {
        dispatch(errorHandler(err));
    }
};

const completeLesson = () => async (dispatch: any, getState: any, { api, errorHandler }: any) => {
    dispatch(requestStarted());
    try {
        const chapter = selectors.getChapter(getState());

        await dispatch(startChapter(chapter));
        await dispatch(fetchSummary());
        dispatch(ScreenAction.initScreen());
        dispatch(requestDone());
    } catch (err) {
        dispatch(errorHandler(err));
    }
};

const isCourseCompleted = () => async (dispatch: any, getState: any) => {
    const store = getState();
    const userAnswers = selectors.getUserAnswers(store);
    const totalQuestions = selectors.getQuestionCount(store);

    return totalQuestions === userAnswers.size
};

const fetchSummary = () => async (dispatch: any, getState: any, { api }: any) => {
    dispatch(requestStarted());

    const courseId = selectors.getCourseId(getState());
    const classroomId = selectors.getClassroomId(getState());

    const courseSummary = await api.getCourseSummary(courseId, classroomId);
    dispatch({ type: SUMMARY_FETCHED, payload: courseSummary.summary });

    dispatch(requestDone());
};

const fetchQuestions = (courseId: string, chapter: string) => async (dispatch: any, getState: any, { api, errorHandler, Type }: any) => {
    try {
        await dispatch(startChapter(chapter));

        dispatch(fetchUserAnswers(courseId));

        let data;
        if (chapter == 'INTRODUCTION.html') {
            data = await api.getIntroduction(courseId);
        } else {
            data = await api.getQuestions(courseId, chapter);
            
        }

        await dispatch({ type: QUESTIONS_FETCHED, payload: data });
        dispatch(ScreenAction.initScreen());

    } catch (err) {
        dispatch(errorHandler(err));
    }
};

const setNextQuestion = () => async (dispatch: any, getState: any) => {
    const questions = selectors.getQuestions(getState()).toArray();
    const currentQuestion = ScreenSelector.getCurrentQuestion(getState());

    const index = findIndex(questions, (q, i) => {
        return questions[i].get('id') == currentQuestion.get('id');
    })

    const nextQuestionIndex = index + 1;

    if(nextQuestionIndex == questions.length){
        dispatch({ type: SET_NEXT_QUESTION, payload: undefined })
    } else {
        dispatch({ type: SET_NEXT_QUESTION, payload: questions[index + 1] })
    }

}

const setQuestionStartTime = () => async (dispatch: any) => dispatch({ type: SET_QUESTION_START_TIME });

export const Action = {
    bootstrap,
    fetchCourse,
    fetchQuestions,
    fetchUserAnswers,
    markAsAnswered,
    retake,
    completeLesson,
    endLesson,
    incompleteLesson,
    isCourseCompleted,
    verifyingQuestionDone,
    verifyingQuestionStarted,
    fetchSummary,
    setQuestionStartTime,
    setNextQuestion
};

// reducers
const initialState = Map({
    classroom: Map({
        classroomId: null,
        showAnswerBtn: false,
        isFetched: false,
        instructorId: '',
        classroomKey: ''
    }),
    course: Map({
        summary: List([]),
        engine: '',
        name: '',
        title: 'Default',
        chapter: Map({
            current: ''
        }),
        isFetched: false,
        mapping: Map({}),
        courseId: '',
        version: ''
    }),
    questions: Map({}), // TODO: should be moved inside course.chapter //lesson questions
    nextQuestion: Map({}),
    user: Map({
        userId: '',
        answers: Map({}),
        answersFetched: false,
        canMoveNextChapter: false,
        doneOnLesson: false,
        doneOnCourse: false,
        incompleteLesson: false,
        startTime: Map({}),
        role: Map({
            isAdmin: false,
            isAuthor: false,
            isInstructor:false
        }),
        isClassroomInstructor: false,
        isGuest: false
    }),
    request: Map({
        pending: false,
        success: true,
        error: false
    }),
    ui: Map({
        question: Map({
            verifying: false
        })
    }),
    error: Map({
        notFound: Map({
            course: false,
            lesson: false,
            user: false,
            classroom: false
        })
    }),
    mode: Map({
        isPreview: false,
        previewType: ''
    })
});

const root = (state = initialState, action: any) => {
    switch(action.type) {
        case VERIFYING_QUESTION_STARTED:
            return state.setIn(['ui', 'question', 'verifying'], true);
        case VERIFYING_QUESTION_DONE:
            return state.setIn(['ui', 'question', 'verifying'], false);
        case Type.REQUEST_FAILED:
            return state.set('request', Map({
                pending: false,
                success: false,
                error: true
            }));
        case USER_ANSWER_POSTED:
            const usersAnswers = state.getIn(['user', 'answers']).toJS();
            usersAnswers[action.payload.questionId] = action.payload;
            return state.setIn(['questions', action.payload.questionId, 'answered'], true)
                .setIn(['user', 'answers'], fromJS(usersAnswers));
        case USER_ANSWER_SKIPPED:
            const answers = state.getIn(['user', 'answers']).toJS();
            answers[action.payload.questionId] = action.payload;
            return state.setIn(['questions', action.payload.questionId, 'skip'], true)
                .setIn(['user', 'answers'], fromJS(answers));
        case COURSE_FETCHED:
            return state.setIn(['course', 'engine'], action.payload.engine.docker_engine)
                .setIn(['course', 'name'], action.payload.course_key)
                .setIn(['course', 'title'], action.payload.name)
                .setIn(['course', 'courseId'], action.payload.id)
                .setIn(['course', 'version'], action.payload.version)
                .setIn(['course', 'isFetched'], true)
        case MAPPING_FETCHED:
            return state.setIn(['course', 'mapping'], fromJS(action.payload));
        case SET_COURSE_ID:
            return state.setIn(['course', 'courseId'], action.payload);
        case SUMMARY_FETCHED:
            return state.setIn(['course', 'summary'], fromJS(action.payload));
        case QUESTIONS_FETCHED:
            const userAnswers = state.getIn(['user', 'answers']);
            const questions = action.payload
                .map((q: any) => q.set('answered', (userAnswers.get(q.get('id').toString()) !== undefined) && !(userAnswers.get(q.get('id').toString()).get('skip')) && !q.get('skip')))
                .map((q: any) => q.set('skip', (userAnswers.get(q.get('id').toString()) !== undefined) ? userAnswers.get(q.get('id').toString()).get('skip') : false));
            return state.set('questions', questions);
        case SET_NEXT_QUESTION:
            return state.set('nextQuestion', action.payload);
        case SET_USER_ROLE_ADMIN:
            return state.setIn(['user', 'role', 'isAdmin'], true);
        case SET_USER_ROLE_AUTHOR:
            return state.setIn(['user', 'role', 'isAuthor'], true);
        case SET_USER_ROLE_INSTRUCTOR:
            return state.setIn(['user', 'role', 'isInstructor'], true);
        case SET_IS_CLASSROOM_INSTRUCTOR:
            return state.setIn(['user', 'isClassroomInstructor'], true);
        case SET_USER_IS_GUEST:
            return state.setIn(['user', 'isGuest'], true);
        case USER_ANSWERS_FETCHED:
            return state.setIn(['user', 'answers'], action.payload)
                .setIn(['user', 'answersFetched'], true);
        case CHAPTER_FETCHED:
            return state.setIn(['course', 'chapter', 'current'], action.payload);
        case CLASSROOM_FETCHED:
            return state.setIn(['classroom', 'classroomId'], action.payload.id)
                .setIn(['classroom', 'enableShowAnswerBtn'], action.payload.settings.enableShowAnswerBtn)
                .setIn(['classroom', 'isFetched'], true)
                .setIn(['classroom', 'instructorId'], action.payload.instructor.platformUserId)
                .setIn(['classroom', 'classroomKey'], action.payload.classroomKey);
        case DONE_ON_LESSON:
            return state.setIn(['user', 'doneOnLesson'], action.payload);
        case DONE_ON_COURSE:
            return state.setIn(['user', 'doneOnCourse'], action.payload);
        case INCOMPLETE_LESSON:
            return state.setIn(['user', 'incompleteLesson'], action.payload);
        case SET_QUESTION_START_TIME:
            return state.setIn(['user', 'startTime'], new Date().getTime());
        case CHAPTER_RESETTED:
            return state.set('questions', OrderedMap(state.get('questions').map((q: any) =>  q.set('answered', false).set('skip', false))));
        case Type.COURSE_NOT_FOUND:
            return state.setIn(['error','notFound', 'course'], action.payload);
        case Type.COURSE_CHAPTER_QUESTIONS_FETCH_ERROR:
            return state.setIn(['error','notFound', 'lesson'], action.payload);
        case Type.CLASSROOM_NOT_FOUND:
            return state.setIn(['error', 'notFound', 'classroom'], action.payload);
        case SET_USER_ID:
            return state.setIn(['user', 'userId'], action.payload);
        case SET_MODE:
            return state.setIn(['mode', 'isPreview'], action.payload.isPreview)
                .setIn(['mode', 'previewType'], action.payload.previewType);
        default:
            return state;
    }
};

export const Reducer = {
    root
};

import { SANDBOX_TEST_ERROR } from '../codestop/codestop-types';

class SandboxTestError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Sandbox Post Service Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = SANDBOX_TEST_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, SandboxTestError);
        }
    }
}

export default SandboxTestError;
import { COURSE_ACCESS_DENIED } from '../codestop/codestop-types';

class CourseAccessDenied extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Course Access Denied'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = COURSE_ACCESS_DENIED;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, CourseAccessDenied);
        }
    } 
}

export default CourseAccessDenied;
import * as types from "./types";
import { Map } from 'immutable';

const initialState = Map({
    status: false
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.TOGGLE_BTN_STATUS:
      return state
        .set('status', action.payload )
    default:
      return state;
  }
};
export const HTML   = "codestop/html-css-1-engine:latest";
export const CSS    = "codestop/html-css-1-engine:latest";
export const NODE     = "codestop/node-8.11-engine:latest";
export const PHP    = "codingavenue/ca-school-php-engine:latest";
export const POSTGRESQL    = "codestop/postgresql:9.6-engine:latest";
export const DOM    = "codestop/dom-1-engine:latest";

export const isHTML = (dockerName:String) => dockerName === HTML;

export const isCSS = (dockerName:String) => dockerName === CSS;

export const isJS = (dockerName:String) => dockerName === NODE;

export const isPHP = (dockerName:String) => dockerName === PHP;

export const isPOSTGRESQL = (dockerName:String) => dockerName === POSTGRESQL;

export const isDOM = (dockerName:String) => dockerName === DOM;


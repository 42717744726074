import * as React from 'react';
import { Button, Icon, Intent } from '@blueprintjs/core'; 
import { Tab, Tabs } from "@blueprintjs/core";
import Frame from './frame';
import BrowserConsole from './browser-console';

interface BrowserDisplayProps {
    toggledDisplay: boolean;
    toggleDisplay: any;
    htmlOutput: string;
    consoleOutput: string;
    label: string;
}

export default class extends React.Component<BrowserDisplayProps, {}> {

    constructor(props: BrowserDisplayProps){
        super(props);
    }

    render() {
        const { htmlOutput, consoleOutput, toggledDisplay } = this.props;

        const toggleIcon = toggledDisplay ? "minimize" : "maximize";

        const titleTag = htmlOutput.match("<title>(.*?)</title>");
        const title = (titleTag && titleTag[1]) || "Untitled";

        const consoleTitle = consoleOutput ? <span>Console <Icon icon="error" intent={Intent.DANGER} /></span> : "Console";
        const browserDisplayWrapClass = toggledDisplay ? "browser-display-wrap" : "browser-display-wrap-half";


        return (
            <div className={browserDisplayWrapClass}>
                <Tabs id="BrowserTabs"
                    defaultSelectedTabId="browser"
                    renderActiveTabPanelOnly={false}
                    className="browser-display-tabs"
                >
                    <Button icon={ toggleIcon }
                            className="browser-toggle-btn"
                            minimal={true}
                            onClick={this.props.toggleDisplay} />
                    <Tab id="browser" title={title} panel={<Frame body={ htmlOutput } />} />
                    <Tab id="console" title={consoleTitle} panel={<BrowserConsole output={ consoleOutput } />} />
                    <Tabs.Expander />
                </Tabs>
                
                <div className="clear-div"></div>

            </div>
        )
    }
}
import * as React from 'react';
import { Cell, Column, Table } from "@blueprintjs/table";
import { isEqual } from 'lodash';

interface SQLTableProps {
    results: any,
    toggledSidebar: boolean
}

interface SQLTableState {
    results: any,
    tableWidth: number
}

export default class SQLTable extends React.Component<SQLTableProps, SQLTableState> {

    constructor(props: SQLTableProps) {
        super(props);
        this.state = SQLTable.getInitialState(props);
    }

    static getInitialState = (props: SQLTableProps) => ({
        results: props.results,
        tableWidth: SQLTable.getTableWidth(props)
    })

    static getDerivedStateFromProps(nextProps: SQLTableProps, prevState: SQLTableState) {

        if (!isEqual(nextProps.results, prevState.results)) {
            return SQLTable.getInitialState(nextProps);
        }

        return null;

    }

    componentDidMount()  {
        window.addEventListener("resize", this.updateTableWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTableWidth);
    }

    updateTableWidth = () => {
        this.setState({ tableWidth: SQLTable.getTableWidth(this.props) });
    }

    static getTableWidth = (props:any) => {
        const tableContainer = document.getElementsByClassName('pt-table-container')[0] as HTMLElement;
        const content = document.getElementsByClassName('content')[0] as HTMLElement;
        const sidebar = document.getElementsByClassName('s-sidebar__nav')[0] as HTMLElement;

        const windowWidth1 = (!props.toggledSidebar ? window.innerWidth - sidebar.offsetWidth : window.innerWidth) - 105;
        const windowWidth2 = window.innerWidth - 105;

        const windowWidth = windowWidth1 <= 480 ? windowWidth2 : windowWidth1;

        const containerWidth = (props.toggledSidebar ? content.offsetWidth + sidebar.offsetWidth : content.offsetWidth) - 40;

        const tableWidth = windowWidth <= containerWidth ? windowWidth : containerWidth;
        const tableStyleWidth = windowWidth <= containerWidth ? `${windowWidth}px` : '100%';

        if(tableContainer){
            tableContainer.style.width = tableStyleWidth;
        }

        return tableWidth;
    }

    render() {
        const { results } = this.props;
        const { tableWidth } = this.state;

        if (results.hasOwnProperty('fields') && results.fields.length > 0) {

            const values = results.rows.map((row: any) => {
                let value:any = [];

                Object.keys(row).map((key) => {
                    value.push(row[key]);
                })

                return value;
            })

            const cellRenderer = (rowIndex: number, columnIndex: number) => {
                return <Cell>{values[rowIndex][columnIndex]}</Cell>
            };

            const getColumnWidths = () => {
                let widths:any = [];
                const colCount = results.fields.length;

                results.fields.map((col:any) => {
                    const colWidth = (tableWidth-45) / colCount;
                    widths.push(colWidth > 150 ? colWidth : 150);
                })

                return widths;
            }

            return (
                <div className="sql-table-wrap">
                    <h4>Result:</h4>
                    <Table numRows={values.length} maxRowHeight={5} minRowHeight={5} minColumnWidth={2} columnWidths={getColumnWidths()} enableRowResizing={false} >
                    {results.fields.map((col: any, i: number) => {
                        return <Column name={col.name} cellRenderer={cellRenderer} key={i} />
                    })}
                    </Table>
                </div>
            )
        }

        return '';
    }
}

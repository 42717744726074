import { USER_ANSWER_FETCH_ERROR } from '../codestop/codestop-types';

class UserAnswerFetchError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'User Answer Fetch Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = USER_ANSWER_FETCH_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, UserAnswerFetchError);
        }
    }
}

export default UserAnswerFetchError;
import * as React from 'react';
import { connect } from 'react-redux';
import CourseNotFound from './course-not-found';
import LessonNotFound from './lesson-not-found';
import { ClassroomNotFound } from './classroom-not-found';
import ReturnTo from '../return-to-link';

const logo = require('./404.png');

interface Props {
    userNotFound: boolean;
    courseNotFound: boolean;
    lessonNotFound: boolean;
    requestError: boolean;
    classroomNotFound: boolean;
}

class NotFound extends React.Component<Props> {
    render() {
        const { requestError, userNotFound, courseNotFound, lessonNotFound, classroomNotFound } = this.props;

        if(requestError){

            return <div style={{margin:'25px 15px', textAlign: 'center'}}>
                    <img alt='logo' style={{ width: '100px', marginBottom: '20px' }} src={logo} />
                    <h2>Oops! Something went wrong.</h2>
                    <p>
                    Unable to process request. Please try again.
                    </p>
                    <ReturnTo />
                </div>;

        } else if(userNotFound){
            // I'm not sure how to replicate this one.
            return <div></div>;
        } else if (classroomNotFound) {
            return <ClassroomNotFound />
    
        } else if(courseNotFound){

            return <CourseNotFound />

        } else if(lessonNotFound){

            return <LessonNotFound />

        } else {
            return <div>{this.props.children}</div>;
        }
    }
}

export default connect(
  (state:any) => ({
      userNotFound: state.main.getIn(['error', 'notFound','user']),
      courseNotFound: state.main.getIn(['error', 'notFound','course']) ,
      lessonNotFound: state.main.getIn(['error', 'notFound','lesson']),
      requestError: state.main.getIn(['request', 'error']),
      classroomNotFound: state.main.getIn(['error', 'notFound', 'classroom'])
  }), {}
)(NotFound)
import * as types from "./types";
import { Map } from 'immutable';

const initialState = Map({
    content: '',
    isLoaded: false,
    isOpen: false,
    tries: 0
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.FACT_LOADED:
      return state
        .set('content', action.payload)
        .set('isLoaded', true)
        .set('isOpen', false)
    case types.FACT_NOT_LOADED:
        return state
          .set('content', action.payload)
          .set('isLoaded',false)
          .set('isOpen', false)
    case types.FACT_OPENED:
      return state
        .set('isOpen', action.payload)
        .set('tries', 0)
    case types.UPDATE_TRY:
      return state
        .set('tries', action.payload)
    default:
      return state;
  }
};
import * as Type from "./types";
import { Map } from 'immutable';

const initialState = Map({
  parent: Map({}),
  child: Map({})
});

export default (state = initialState, action: any) => {
    switch(action.type) {
        case Type.CLEAR_SCREENS:
            return state
              .set("parent", Map({}))
              .set("child", Map({}));
        case Type.CLEAR_PARENT_SCREEN:
            return state.set("parent", Map({}));
        case Type.PARENT_SCREEN_LOADED:
            return state.set("parent", action.payload);
        case Type.SCREEN_LOADED:
            return state.set("child", action.payload);
        default:
            return state;
    }
}
export const getNode = (state: any) => state.questnavigation;

export const isPending = (state: any): boolean => getNode(state).get('isPending');

export const isFailed = (state: any): boolean => getNode(state).get('isFailed');

export const isSuccess = (state: any): boolean => getNode(state).get('isSuccess');

export const isDone = (state: any): boolean => getNode(state).get('isDone');

// TODO: need to check if all questions in the chapter are done
export const canNext = (state: any): boolean => (isDone(state) && isSuccess(state)) || getNode(state).get('canNext');
export const canPrev = (state: any): boolean => getNode(state).get('canPrev');
export const canStart = (state: any): boolean => getNode(state).get('canStart');
export const canEnd = (state: any): boolean => getNode(state).get('canEnd');

export const isLastQuestion = (state: any): boolean => getNode(state).get('isLastQuestion');
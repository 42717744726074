import * as React from 'react';

interface BrowserConsoleProps {
    output: string;
}

export default class extends React.Component<BrowserConsoleProps, {}> {

    constructor(props: BrowserConsoleProps){
        super(props);
    }

    render() {
        const { output } = this.props;

        return (
            <div className="browser-console-output">
                <code>{output}</code>
            </div>
        )
    }
}
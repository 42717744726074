import * as React from "react";
import { Dialog, Button, Intent, Tooltip, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";

interface FactProps {
    fact: string;
    isOpen: boolean;
    tries: number;
    resetTry: any;
    setIsOpen: any;
}

interface FactState {
    fact: string,
    popOpen: boolean
}

export default class Fact extends React.Component<FactProps, FactState> {
    constructor(props: FactProps) {
        super(props);
        this.state = Fact.getInitialState(props);
    }

    static getInitialState = (props: FactProps) => ({
        fact: props.fact,
        popOpen: false
    })

    static getDerivedStateFromProps(nextProps: FactProps, prevState: FactState) {

        if (nextProps.fact !== prevState.fact) {
            return Fact.getInitialState(nextProps);
        }

        return {
            popOpen: (!nextProps.tries || nextProps.tries % 3) ? false : true
        }

    }
    
    render() {
        const { fact, isOpen, tries } = this.props;

        const factContent = fact ? fact : 'Overview not available right now!';
        const activeClass = tries >= 3 ? "active-fact" : "";

        const button = <Popover
                        interactionKind={PopoverInteractionKind.CLICK}
                        popoverClassName="pt-popover-content-sizing"
                        position={Position.BOTTOM}
                        isOpen = { this.state.popOpen }
                        onInteraction={(state) => this.handleInteraction(state)}
                    >
                        <Tooltip content="Overview" position={Position.BOTTOM}>
                            <Button onClick={this.toggleDialog} text="" className={"pt-button pt-minimal pt-icon-lightbulb " + activeClass} />
                        </Tooltip>
                        <div id="popText">
                            Do you need some help?
                        </div>
                    </Popover>

        return (
            <div className="fact-icon">
                    { button }
                    <Dialog
                        icon="lightbulb"
                        isOpen={ isOpen }
                        onClose={this.toggleDialog}
                        title="Overview"
                        style={{width: '970px'}}
                    >
                        <div className="pt-dialog-body">
                        <div dangerouslySetInnerHTML={{ __html: factContent }} />
                        </div>
                        <div className="pt-dialog-footer">
                            <div className="pt-dialog-footer-actions">
                                <Button
                                    intent={Intent.PRIMARY}
                                    onClick={this.toggleDialog}
                                    text="Close"
                                />
                            </div>
                        </div>
                    </Dialog>
            </div>
        );
    }

    private toggleDialog = () => this.props.setIsOpen(!this.props.isOpen);
    private handleInteraction = (nextOpenState: boolean) => this.setState({ popOpen: nextOpenState });
}

import { CLASSROOM_ACCESS_DENIED } from  '../codestop/codestop-types';

class ClassroomAccessDenied extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Classroom Access Denied'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args ? args.code : 401;
        this.name = CLASSROOM_ACCESS_DENIED;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, ClassroomAccessDenied);
        }
    } 
}

export default ClassroomAccessDenied;
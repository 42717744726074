import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Reducer } from './state';
import * as api from './api';
import * as queue from './lib/queue';
import * as AchieveApi from './lib/api/achieve';
import * as ActivityTrackerApi from './lib/api/activity-tracker';
import * as toaster from './lib/toaster';
import * as topbar from './lib/topbar';
import * as Type from './lib/codestop/codestop-types';
import * as mode from './lib/codestop/codestop-modes';
import { errorHandler } from './lib/error-handler';
import { Reducer as ConsoleReducer } from './app/console';
import { Reducer as TipsReducer } from './app/tips';
import { Reducer as QuestionNavigationReducer } from './app/question-navigation';
import { Reducer as FactReducer } from './app/fact';
import { Reducer as ScreenReducer } from './app/screen';
import { Reducer as SidebarReducer } from './app/sidebar';
import { Reducer as ShowAnswerBtnReducer } from './app/show-answer-btn';
import { Reducer as BrowserDisplayReducer } from './app/browser-display';
import { Reducer as SQLTableReducer } from './app/sql-table';
import { Reducer as SQLConsoleReducer } from './app/sql-console';

export const configure = () => {
    return createStore(
        combineReducers({
            main: Reducer.root,
            console: ConsoleReducer,
            tips: TipsReducer,
            questnavigation: QuestionNavigationReducer,
            fact: FactReducer,
            screen: ScreenReducer,
            sidebar: SidebarReducer,
            showanswerbtn: ShowAnswerBtnReducer,
            browserdisplay: BrowserDisplayReducer,
            sqltable: SQLTableReducer,
            sqlconsole: SQLConsoleReducer
        }),
        applyMiddleware(
            thunkMiddleware.withExtraArgument({ api, queue, toaster, topbar, AchieveApi, ActivityTrackerApi, Type, errorHandler, mode })
        )
    );
}
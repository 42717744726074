import * as React from 'react';
import { Button, Popover, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { isEqual } from 'lodash';

interface QuestionNavigationProps {
    data: any;
    onJump: any;
    canNext: any;
    canPrev: any;
    canStart: any;
    canEnd: any;
    currentQuestion: any;
    nextUnansweredQuestion: any;
    doneOnLesson: boolean;
    initScreen: any;
    showSidebar: any;
    userCanViewQuestions: boolean;
}

interface QuestionNavigationState {
    currentQuestionId: string,
    data: any,
    numbers: any[];
}

export default class QuestionNavigation extends React.Component<QuestionNavigationProps, QuestionNavigationState> {
    constructor(props: QuestionNavigationProps) {
        super(props);
        this.state = QuestionNavigation.getInitialState(props);
    }

    static getInitialState = (props: QuestionNavigationProps) => ({
        currentQuestionId: props.currentQuestion.get('id'),
        data: props.data,
        numbers: QuestionNavigation.buildNumberList(props),
    });

    static getDerivedStateFromProps(nextProps: QuestionNavigationProps, prevState: QuestionNavigationState) {

        if (!isEqual(nextProps.data, prevState.data) || nextProps.currentQuestion.get('id') !== prevState.currentQuestionId) {
            return QuestionNavigation.getInitialState(nextProps);
        }

        return null;

    }

    static onClickHandler = (props:any) => {
        props.initScreen();
        props.showSidebar();
    }

    static buildNumberList = (props: any) => {

        const questionNumbers:any = [];

        props.data.toArray().map((number:any, i:number)=> {

            const answered = number.get('answered') ? 'pt-intent-success' : '';
            const selected = number.get('id') == props.currentQuestion.get('id') ? 'selected-question' : '';

            let active = '';
            let disabled = false;

            if(props.nextUnansweredQuestion){
                active = (number.get('id') == props.nextUnansweredQuestion.get('id')) ? 'active-question' : '';

                if(!props.userCanViewQuestions) {
                    disabled = !number.get('skip') && !number.get('answered') && props.nextUnansweredQuestion.get('id') != number.get('id');
                }
            }

            questionNumbers.push(
                <Button className = {`pt-minimal numbtn ${answered} ${selected} ${active}`}
                        onClick = {() => props.onJump(number.get('id'))}
                        disabled = { disabled }
                        key={i}>{i+1}</Button>
            )
        })

        const doneClass = props.doneOnLesson ? 'pt-intent-success' : '';
        const doneSelected = props.doneOnLesson && !props.currentQuestion.size ? 'selected-question active-question' : '';

        questionNumbers.push(
            <Button className = {`pt-minimal ${doneClass} ${doneSelected}`}
                    onClick = { () => { QuestionNavigation.onClickHandler(props);} }
                    disabled = { !props.doneOnLesson }
                    key={Math.random()}>Done</Button>
        )

        return questionNumbers;
    }

    render() {
        const { data } = this.props;
        const { numbers } = this.state;

        let currentNumber = 0;
        let currentNumberClass = this.props.currentQuestion.get('answered') ? 'pt-intent-success' : 'pt-tag-unanswered';

        data.toArray().map((number:any, i:number)=> {
            if(number.get('id') == this.props.currentQuestion.get('id')){
                currentNumber = (i+1);
            }
        })

        const navLabel = currentNumber ? 'Question' : '';
        const navButton = currentNumber ?
                                        <Tag
                                            className={currentNumberClass}
                                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                                        >
                                            {currentNumber}
                                        </Tag> :
                                        <Button className='pt-tag-unanswered'>View all Questions</Button>

        return <nav className="question-navigation pt-navbar pt-dark .modifier">

                <div className="pt-navbar-group pt-align-center">
                    {navLabel}
                    {/* <Button className="pt-button pt-minimal pt-icon-double-chevron-left start-button" disabled={!canStart} onClick={ onStart }><span className="text">START</span></Button>
                    <Button className="pt-button pt-minimal pt-icon-chevron-left prev-button" disabled={!canPrev} onClick={ () => onMove('prev') }><span className="text">PREV</span></Button> */}

                    <Popover
                        interactionKind={PopoverInteractionKind.CLICK}
                        popoverClassName="pt-popover-content-sizing"
                        position={Position.TOP}
                        portalClassName="question-navigation-popover"
                    >
                        { navButton }
                        <div>
                            <p style={{ textAlign: 'center' }}>
                                {numbers}
                            </p>
                        </div>
                    </Popover>

                    {/* <Button className="pt-button pt-minimal next-button" disabled={!canNext} onClick={ () => onMove('next') }>
                        <span className="text">NEXT</span>
                        <span className="pt-icon-standard pt-icon-chevron-right pt-align-right"></span>
                    </Button>
                    <Button className="pt-button pt-minimal end-button" disabled={!canEnd} onClick={ onEnd }>
                        <span className="text">END</span>
                        <span className="pt-icon-standard pt-icon-double-chevron-right pt-align-right"></span>
                    </Button> */}
                </div>
            </nav>
    }
}
import { USER_COURSE_ACCESS_ERROR } from '../codestop/codestop-types';

class UserCourseAccessError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'User Course Access Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = USER_COURSE_ACCESS_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, UserCourseAccessError);
        }
    }
}

export default UserCourseAccessError;
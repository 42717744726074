import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Selectors from "./../app/selectors";
import { Selector, Action } from "./../app/sidebar";
import { Tooltip, Position } from "@blueprintjs/core";
import ReturnTo from './return-to-link';
import PlatformLink from './platform-link';

interface NavigationProps {
    classroomId: string;
    courseName: any;
    courseTitle: any;
    toggle: boolean;
    toggleSidebar: any;
    isAdmin: boolean;
    isAuthor: boolean;
}

class Navigation extends React.Component<NavigationProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { courseTitle } = this.props;

        const activeClass = this.props.toggle ? "" : "pt-active";
        const sidebarIcon = this.props.toggle ? "pt-icon-menu-closed" : "pt-icon-menu-open";
        const tipContent = this.props.toggle ? "Show Sidebar" : "Hide Sidebar";

        return (
            <div>
                <nav className="pt-navbar pt-dark pt-fixed-top navbar-main">
                    <div className="navbar-wrapper">
                        <div className="pt-navbar-group pt-align-left">
                            <PlatformLink classNames="pt-button pt-minimal return-to-dashboard">
                                <ReturnTo classNames="pt-button pt-minimal pt-icon-chevron-left return-to-dashboard"/>
                            </PlatformLink>
                        </div>
                        <div className="pt-navbar-group pt-align-right">
                            <Tooltip content={tipContent} position={Position.BOTTOM_RIGHT} inheritDarkTheme={false} >
                                <button className={`pt-button pt-minimal sidebar-toggle ${activeClass} ${sidebarIcon} `} onClick={this.toggleSidebar} ></button>
                            </Tooltip>
                        </div>
                        <div className="pt-navbar-group pt-align-center">
                            <div className="pt-navbar-heading">
                                <strong>{courseTitle}</strong>
                            </div>
                        </div>
                    </div>
                </nav>
                <nav className="pt-navbar pt-dark pt-fixed-top navbar-mobile">
                    <div className="navbar-wrapper">
                        <div className="pt-navbar-group pt-align-left">
                            <ReturnTo classNames="pt-button pt-minimal pt-icon-chevron-left return-to-dashboard"/>
                        </div>
                        <div className="pt-navbar-group pt-align-right">
                            <Tooltip content={tipContent} position={Position.BOTTOM_RIGHT} inheritDarkTheme={false} >
                                <button className={`pt-button pt-minimal sidebar-toggle ${activeClass} ${sidebarIcon} `} onClick={this.toggleSidebar} ></button>
                            </Tooltip>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }

    private toggleSidebar = () => this.props.toggleSidebar(!this.props.toggle);
}

export default connect((state: any) => ({
    courseTitle: Selectors.getCourseTitle(state),
    courseName: Selectors.getCourseName(state),
    classroomId: Selectors.getClassroomId(state),
    toggle: Selector.getToggle(state),
    isAdmin: Selectors.userRoleIsAdmin(state),
    isAuthor: Selectors.userRoleIsAuthor(state)
}),
    (dispatch: any) => ({
        toggleSidebar: bindActionCreators(Action.toggleSidebar, dispatch)
    })
)(Navigation);

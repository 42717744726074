import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, Intent } from "@blueprintjs/core";

interface State {
    isOpen: boolean;
}

class CheckDisplay extends React.Component<{},State> {

    constructor(props: any) {
        super(props);
        this.state = { isOpen: true };
    }

    onContinue = () => {
        this.setState({ isOpen: false });
    }

    render() {

        const { isOpen } = this.state;

        if(window.innerWidth <= 480 && isOpen){
            return <Alert
                    className="pt-dark"
                    confirmButtonText="Continue"
                    icon="info-sign"
                    intent={Intent.PRIMARY}
                    isOpen={isOpen}
                    onClose={this.onContinue}
                >
                    <p>
                        Yes, you can surely do programming on your phone but it is easier with large screen, keyboard and a mouse.
                    </p>
                </Alert>
        }

        return this.props.children;
    }
}

export default connect()(CheckDisplay)

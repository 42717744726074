import * as React from 'react';
import Question from './question';
import { Spinner } from '@blueprintjs/core';

interface ScreenProps {
    screen: any;
}

export default class extends React.Component<ScreenProps, {}> {
    render() {
        const { screen } = this.props;

        let subheaderComponent, replComponent = undefined;
        if (!screen.get('parent').isEmpty()) {

            const subheader = screen.getIn(['parent', 'parent']);
            if (subheader !== undefined) {
                subheaderComponent = <Question question={subheader} />;
            }

            if (screen.get("parent").get("type").match(/(CR|REPL)/)) {
              replComponent = <Question question={screen.get("parent")} />;
            } 
        }

        return <div className="screen">
            {subheaderComponent}
            {replComponent}
            {screen
              .get("child")
              .isEmpty() ? <Spinner className="pt-small" /> : <Question question={screen.get("child")} />}
          </div>;
    }
}
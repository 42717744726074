import { Position, Toaster, Intent } from "@blueprintjs/core";

const toaster = Toaster.create({
    className: 'my-toaster',
    position: Position.BOTTOM_RIGHT
});

export const dismiss = (key: any) => toaster.dismiss(key);
export const clear = () => toaster.clear();

export const notifyError = (message: string) => toaster.show({
    message,
    intent: Intent.DANGER,
    icon: 'error',
});

export const notifyMessage = (message: string) => toaster.show({
    message,
    intent: Intent.PRIMARY,
    icon: 'info-sign',
});

export const notifyIncorrect = (message: string) => toaster.show({
    message,
    intent: Intent.WARNING,
    icon: 'warning-sign',
    timeout: 5000
});

export const notifyCorrect = (message: string) => toaster.show({ 
    message, 
    intent: Intent.SUCCESS,
    icon: 'tick'
});
import Axios from 'axios';
import config from '../../config';
import { TimeoutError } from '../errors';

const SAFE_HTTP_METHODS = ['get', 'head', 'options'];

export default class CodeStopApi {
    private request: any;

    constructor(baseURL: string, config: any = {}) {
        const axios = Axios.create({ baseURL: baseURL, timeout: 5000 });
        config = { retry: 1, ...config };

        axios.interceptors.response.use((response) => {
            return response;
        }, (error: any) => {
            if ((error.config && SAFE_HTTP_METHODS.indexOf(error.config.method) !== -1) || config.retry > 1) {
                let retryCount = 0;
                const maxRetry = config.retry > 1 ? config.retry : 3;
                if (typeof error.config.headers['X-Retry-Count'] !== 'undefined') {
                    retryCount = error.config.headers['X-Retry-Count'];
                }

                if (retryCount < maxRetry) {
                    retryCount++;
                    error.config.headers['X-Retry-Count'] = retryCount;
                    return axios.request(error.config);
                }
            }

            if (error.code === 'ECONNABORTED' || error.response.status === 408) {
                return Promise.reject(new TimeoutError(error.message));
            }

            return Promise.reject(error);
        });

        this.request = axios;
    }

    getAuthorizeHeader(token: any) {
        return {
            "headers": {
                "Authorization": token
            }
        }
    }

    /**
     * Gets the token from the cookie.
     * @return {String|undefined} the token found
     */
    getToken() {
        const value = "; " + document.cookie;
        const parts = value.split("; token" + "=");

        if (parts.length === 2) {
            const part1 = parts.pop() || '';
            const part2 = part1.split(';');
            const part3 = part2.shift() || '';
            return decodeURI(part3);
        } else {
            /**
             * If no token is found, we assume this is a guest user and we use our readonly token
             */

            return config.readonlyToken;
        }
    }

    /**
     * Creates a GET request
     * 
     * @param {String} path the path of the request that will be appended to the baseURL
     * @param {Object} param Axios config object. Axios will merge this one with the instance config.
     * @return {Axios Request Promise}
     */
    async get(path: string, param?: any) {
        try {
            const token = await this.getToken()
            const header = this.getAuthorizeHeader(token);

            const response = await this.request.get(path, { ...param, ...header });

            this.verifyResponse(response);

            return response;
        } catch(e) {
            this.verifyErrorResponse(e);
            throw e;
        }
    }

    /**
     * Creates a POST request
     * 
     * @param {String} path the path of the request that will be appended to the baseURL
     * @param {Object} param the request payload
     * @param {Object} config Axios config object. Axios will merge this one with the instance config.
     * @return {Axios Requet Promise}
     */
    async post(path: string, param?: any, query?:any, config = {}) {
        try {
            const token = await(this.getToken())
            const header = this.getAuthorizeHeader(token);

            const response = await this.request.post(path, param, { ...query, ...config, ...header })

            this.verifyResponse(response);

            return response;
        } catch(e) {
            this.verifyErrorResponse(e)
        }
    }

    async delete(path: string, config = {}) {
        try {
            const token = await this.getToken();
            const header = this.getAuthorizeHeader(token);

            const response = await this.request.delete(path, { ...config, ...header })
            

            this.verifyResponse(response);

            return response;
        } catch(e) {
            this.verifyErrorResponse(e);
        }
    }


    /**
     * Verify  if response is valid
     *
     * @param {Object} param Response object
     */
    verifyResponse(response: any) {
        if (response.status == 403) {
            return;
        }
    }

    /**
     * Verify Error Response
     * 
     * @param {Object} param the Error object
     */
    verifyErrorResponse(error: any) {
        if (error.response) { // We receive a response from the server but status code is not within the 2xx range
            this.verifyResponse(error.response)
        }

        throw error;
    }

    /**
     * Redirect reader to platform login page.
     */
    redirectToLogin() {
        window.location.assign(config.platformLoginURL);
    }
}

import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../app/selectors';
import { platform as platformUrl } from "../lib/platform-base-url";

interface Props {
    isAdmin: boolean;
    isAuthor: boolean;
    isInstructor: boolean;
    isStudent: boolean;
    courseKey: string;
    classNames?: string;
    classroomKey: string;
}

class ReturnToLink extends React.Component<Props> {

    getUrl() {
        const { isAdmin, isAuthor, isInstructor, isStudent, courseKey, classroomKey } = this.props;

        if (isAdmin) {
            return courseKey
                ? platformUrl(`/admin/course/view/${courseKey}`)
                : platformUrl(`/admin/course/list`);
        } else if (isAuthor) {
            return courseKey
                ? platformUrl(`/course-manage/view/${courseKey}`)
                : platformUrl(`/course-manage/`);
        } else if (isInstructor) {
            return classroomKey
                ? platformUrl(`/classroom/view/${classroomKey}/outline/course`)
                : platformUrl('/classroom/created');
        } else if (isStudent) {
            return classroomKey
                ? platformUrl(`/classroom/view/${classroomKey}`)
                : platformUrl(`/my-enrolled-courses`);
        }
        else {
            return platformUrl(`/course/view/${courseKey}`)
        }
    }

    getUrlName() {
        const { isAdmin, isAuthor, isInstructor, isStudent, courseKey, classroomKey } = this.props;

        if (isAdmin) {
            return courseKey
                ? 'Course Preview'
                : 'Admin Course List';
        } else if (isAuthor) {
            return courseKey
                ? 'Course Preview'
                : 'Course Manage';
        } else if (isInstructor) {
            return classroomKey
                ? 'Classroom Course Preview'
                : 'My Classrooms';
        } else if (isStudent) {
            return classroomKey
                ? 'Classroom Course View'
                : 'My Enrolled Courses';
        } else {
            return 'Course Outline'
        }
    }

    render() {
        return <a href={ this.getUrl() } className={ this.props.classNames }>{ this.getUrlName() }</a>;
    }
}

export default connect(
    (state: any) => ({
        isAdmin: selectors.userRoleIsAdmin(state),
        isAuthor: selectors.userRoleIsAuthor(state),
        isInstructor: selectors.userIsClassroomInstructor(state),
        isStudent: selectors.userIsStudent(state),
        courseKey: selectors.getCourseName(state),
        classroomKey: selectors.getClassroomKey(state)
    })
)(ReturnToLink)

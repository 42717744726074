let queue:any = [];
let qFlag:number = 1;

export const init = () => (dispatch:any) => {
    setInterval(() => {
        if(qFlag){
            dispatch(dequeue());
        }
    }, 1000);
}

export const enqueue = (method: any, param: any) => {
    queue.push({method,param});
}

export const dequeue = () => (dispatch:any) => {
    queue.forEach((q:any, i:number) => {
        qFlag = 0;
        dispatch(q.method(q.param));
        queue.shift();
    })

    qFlag = 1;
}
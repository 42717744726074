import * as types from './types';
import { trim } from 'lodash';

export const showOutput = (result: any) => (dispatch: any) => {

    const output = typeof result === 'string' ? result : constructOutput(result);
    dispatch({ type: types.OUTPUT_ADD, payload: trim(output).length > 0 ? output : '_' });

}

const constructOutput = (result:any) => {

    const rowCount = result.rowCount;
    const command = result.command;

    switch(command){
        case types.CREATE:
                    return "CREATE TABLE";
        case types.SELECT: 
                    return `(${rowCount} row)`;
        case types.UPDATE:
        case types.DELETE:
                    return `${command} ${rowCount}`;
        default: 
                return `${command} 0 ${rowCount}`;
    }

}
    
export const clear = () => (dispatch: any) => dispatch({ type: types.CLEAR });
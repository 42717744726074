import * as types from './types';

const initialState = {
    logs: [
        {
            message: '_'
        }
    ]
};

export default (state = initialState, action: any) => {
    switch(action.type) {
        case types.OUTPUT_ADD:
            return { ...state, logs: [{ message: action.payload }] };
        case types.CLEAR:
            return initialState;
        default:
            return state;
    }
}
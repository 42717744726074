import SQLTable from '../../components/sql-table';
import { connect } from 'react-redux';
import * as Selector from './selectors';
import * as SidebarSelector from './../sidebar/selectors';

export default connect(
    (state: any) => ({
        results: Selector.getResults(state),
        toggledSidebar: SidebarSelector.getToggle(state)
    })
)(SQLTable);
import { USER_ANSWER_POST_ERROR } from '../codestop/codestop-types';

class UserAnswerPostError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'User Answer Post Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = USER_ANSWER_POST_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, UserAnswerPostError);
        }
    }
}

export default UserAnswerPostError;
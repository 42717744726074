import { SANDBOX_EVAL_ERROR } from '../codestop/codestop-types';

class SandboxEvalError extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Sandbox Eval Service Error'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = SANDBOX_EVAL_ERROR;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, SandboxEvalError);
        }
    } 
}

export default SandboxEvalError;
import * as React from "react";
import ReturnTo from '../return-to-link';

const logo = require("./404.png");

export class ClassroomNotFound extends React.Component<{}> {
    render() {
        return (
            <div style={{ margin: "25px 15px", textAlign: "center" }}>
                <img
                    alt="logo"
                    style={{ width: "100px", marginBottom: "20px" }}
                    src={logo}
                />
                <h2>Classroom not found</h2>
                <p>
                    <b>Error 404: </b>
                    Looks like you've followed a broken link or entered a URL
                    that doesn't work on this site.
                </p>
                <ReturnTo />
            </div>
        );
    }
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Selector from './selectors';
import * as Selectors from '../selectors';
import * as Action from './actions';
import { Selector as ScreenSelector, Action as ScreenAction } from './../screen';
import { Action as SidebarAction } from './../sidebar';
import QuestionNavigation from '../../components/question-navigation';

export default connect(
    state => ({
        data: Selectors.getQuestions(state),
        canNext: Selector.canNext(state),
        canPrev: Selector.canPrev(state),
        canStart: Selector.canStart(state),
        canEnd: Selector.canEnd(state),
        currentQuestion: ScreenSelector.getCurrentQuestion(state),
        nextUnansweredQuestion: Selectors.getNextUnSkippedQuestion(state),
        doneOnLesson: Selectors.doneOnLesson(state),
        userCanViewQuestions: Selectors.userIsNotStudent(state)
    }),
    dispatch => ({
        onJump: bindActionCreators(Action.onJump, dispatch),
        initScreen: bindActionCreators(ScreenAction.initScreen, dispatch),
        showSidebar: bindActionCreators(SidebarAction.showSidebar, dispatch)
    })
)(QuestionNavigation);
import * as Type from './codestop/codestop-types';
import { notifyError } from './toaster';
import { platform } from './platform-base-url';

const ErrorMessage = {
    "CR": "Unable to check your answer as of the moment. Please refresh this page or try again in a few minutes.",
    "REPL": "Unable to run your code. Please refresh this page or try again in a few minutes.",
    "ACHIEVE": "Something went wrong and we're not able to save your progress. Please refresh this page or try again in a few minutes.",
    "DEFAULT": "Unable to process your request right now. Please refresh this page. If that does not work, try again after a few minutes.",
    "TIMEOUT": "Poor Connection detected, unable to process your request due to a timeout. Please try again in a few minutes.",
    "REFUSED": "Could not connect to server.",
    "OUTPUT_TOO_LONG": "Unable to run your code. Your code might be taking too long to execute. Please edit your code and run it again."
}

export const errorHandler = (error: any, source:string = '') => (dispatch: any) => {
    if (error.code === 'ECONNABORTED') { // Timeout handler
        notifyError(ErrorMessage.TIMEOUT);
    } else {
        switch(error.name) {
            case Type.CLASSROOM_NOT_FOUND:
                dispatch({ type: Type.CLASSROOM_NOT_FOUND, payload: true });
                break;
            case Type.COURSE_NOT_FOUND:
                dispatch({ type: Type.COURSE_NOT_FOUND, payload: true });
                break;
            case Type.COURSE_CHAPTER_QUESTIONS_FETCH_ERROR:
                dispatch({ type: Type.COURSE_CHAPTER_QUESTIONS_FETCH_ERROR, payload: true})
                break;
            case Type.USER_ROLE_FETCH_ERROR:
            case Type.COURSE_SUMMARY_FETCH_ERROR :
                dispatch({ type: Type.REQUEST_FAILED, payload: true });
                break;
            case Type.SANDBOX_EVAL_ERROR:
                notifyError(ErrorMessage.REPL);
                break;
            case Type.SANDBOX_TEST_ERROR:
                notifyError(ErrorMessage.CR);
                break;
            case Type.USER_ANSWER_POST_ERROR:
            case Type.USER_SHOW_ANSWER_POST_ERROR:
                notifyError(ErrorMessage.ACHIEVE);
                break;
            case Type.CODESTOP_TIMEOUT_ERROR:
                notifyError(ErrorMessage.TIMEOUT);
                break;
            case Type.CLASSROOM_ACCESS_DENIED:
                window.location.assign(platform("/login"))
                break;
            default:
                notifyError(ErrorMessage.DEFAULT);
                break;
        }
    }
}


export const CLASSROOM          = "@codestop/CLASSROOM";
export const COURSE             = "@codestop/COURSE";
export const LESSON             = "@codestop/LESSON";
export const FACTS              = "@codestop/FACTS";
export const REPL               = "@codestop/REPL";
export const CR                 = "@codestop/CR";
export const USER_ANSWER        = "@codestop/USER_ANSWER";
export const ACHIEVE            = "@codestop/ACHIEVE";
export const ACTIVITY_TRACKER   = "@codestop/ACTIVITY_TRACKER";
export const MAPPING            = "@codestop/MAPPING";

export const LESSON_NOT_FOUND   = 'LESSON_NOT_FOUND';

export const REQUEST_STARTED    = 'REQUEST_STARTED';
export const REQUEST_DONE       = 'REQUEST_DONE';
export const REQUEST_FAILED     = 'REQUEST_FAILED';

export const DEFAULT            = 'DEFAULT';
export const CORRECT            = 'CORRECT';
export const INCORRECT          = 'INCORRECT';
export const CONTINUE           = 'CONTINUE';
export const CORRECT_CR         = 'CORRECT_CR';
export const INCORRECT_CR       = 'INCORRECT_CR';

export const OUTPUT_TOO_LONG    = 'OUTPUT_TOO_LONG';
export const NO_PERMISSION      = 'NO_PERMISSION';

// ERROR TYPES

export const USER_NOT_FOUND                 = 'USER_NOT_FOUND';
export const USER_ROLE_FETCH_ERROR          = 'USER_ROLE_FETCH_ERROR';
export const USER_ANSWER_POST_ERROR         = 'USER_ANSWER_POST_ERROR';
export const USER_SHOW_ANSWER_POST_ERROR    = 'USER_SHOW_ANSWER_POST_ERROR';
export const USER_COURSE_ACCESS_ERROR       = 'USER_COURSE_ACCESS_ERROR';
export const USER_COURSE_STARTED_ERROR      = 'USER_COURSE_STARTED_ERROR';
export const USER_ANSWER_FETCH_ERROR        = 'USER_ANSWER_FETCH_ERROR';

export const CLASSROOM_NOT_FOUND        = 'CLASSROOM_NOT_FOUND';
export const CLASSROOM_ACCESS_DENIED    = 'CLASSROOM_ACCESS_DENIED';

export const COURSE_ERROR               = 'COURSE_ERROR'; // base/generic course API error. 
export const COURSE_NOT_FOUND           = 'COURSE_NOT_FOUND';
export const COURSE_ACCESS_DENIED       = 'COURSE_ACCESS_DENIED';

export const COURSE_SUMMARY_FETCH_ERROR             = 'COURSE_SUMMARY_FETCH_ERROR';
export const COURSE_CHAPTER_QUESTIONS_FETCH_ERROR   = 'COURSE_CHAPTER_QUESTIONS_FETCH_ERROR';

export const SANDBOX_EVAL_ERROR         = 'SANDBOX_EVAL_ERROR';
export const SANDBOX_TEST_ERROR         = 'SANDBOX_TEST_ERROR';

export const CODESTOP_TIMEOUT_ERROR     = 'CODESTOP_TIMEOUT_ERROR';





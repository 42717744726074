import * as types from './types';

const initialState: string[] = [];

export default (state = initialState, action: any) => {
    switch(action.type) {
        case types.TIPS_LOADED:
            return action.payload;
        case types.TIPS_CLEARED:
            return initialState;
        default:
            return state;
    }
}
import * as React from 'react';

interface Props {
    tips: string[];
}

export default class extends React.Component<Props, {}> {
    render() {
        return <div className="tips">
            {this.props.tips.map((tip: any, i: number) =>
                <div key={i}><div className="pt-callout pt-intent-danger">
                    <h5>Problem {i + 1}</h5>
                    {tip}
                </div>
                    <br />
                </div>)}
        </div>
    }
}
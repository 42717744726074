import { CLASSROOM_NOT_FOUND } from '../codestop/codestop-types';

class ClassroomNotFound extends Error {
    code?: any;

    constructor(args?: any) {
        const message = args === undefined 
            ? 'Classroom Not Found'
            : typeof args === 'string'
                ? args
                : args.message;

        super(message);
        this.code = args.code;
        this.name = CLASSROOM_NOT_FOUND;

        if('captureStackTrace' in Error){
            Error.captureStackTrace(this, ClassroomNotFound);
        }
    }
}

export default ClassroomNotFound;
import * as types from './types';
import * as ShowAnswerBtnSelector from './selectors';
import * as Selector from "../selectors";

export const toggleShowAnswerBtnStatus = (questionId: string) => (dispatch: any, getState: any, { AchieveApi }:any) => {

    const showAnswerBtnStatus = ShowAnswerBtnSelector.getShowAnswerBtnStatus(getState());

    if(!showAnswerBtnStatus){
        AchieveApi.postShowAnswer({
            classroomId: Selector.getClassroomId(getState()),
            courseId: Selector.getCourseName(getState()),
            chapter: Selector.getChapter(getState()),
            questionType: Selector.getQuestions(getState()).get(questionId).get('type'),
            questionId,
            date: new Date(Date.now()).toString()
        });
    }

    dispatch({ type: types.TOGGLE_BTN_STATUS, payload: !showAnswerBtnStatus });
}

export const resetShowAnswerBtnStatus = () => (dispatch: any, getState: any) =>
    dispatch({ type: types.TOGGLE_BTN_STATUS, payload: false });